import { Box, Typography } from "@mui/material";

export default function ValueCard({ id, title, description }) {
  return (
    <>
      <Box display="flex" flexDirection="row" maxWidth={{xs: "600px", md: "514px"}} gap={{xs: "10px", md: "25px"}} padding={{xs: "10px 16px", md: "25px 30px"}} sx={{backgroundColor: "white"}}>
        <Box height={{xs: "85px", md: "100px"}} component="img" src={"/images/main/img_main_value" + id + ".png"} alt={title.split(" ")[0]} />
        <Box maxWidth={{md: "390px"}}>
          <Typography fontWeight="700" fontSize={{xs: "14px", md: "20px"}}>{title}</Typography>
          <Typography marginTop={{xs: "10px", md: "16px"}} fontSize={{xs: "12px", md: "16px"}} whiteSpace="pre-wrap" sx={{wordBreak: {xs: "break-all", md: "keep-all"}}}>{description}</Typography>
        </Box>
      </Box>
    </>
  )
}
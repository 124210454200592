import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { Dimmed } from "../components/Dimmed";

export default function LoadingModal ({show, setShow, eventCancel}) {
  return (
    <>
      <Dimmed show={show} setShow={setShow} />
      <Box className="modal-wrap" top="50%" width={{xs: "275px", md: "auto"}} display={show?null:"none"}>
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Box display="flex" justifyContent="center" marginBottom="20px"><CircularProgress size="100px"/></Box>
          <Typography color="white" fontSize={{xs: "20px", md: "24px"}} textAlign="center">AI가 제출한 기업 정보에 적합한 법정 의무 교육을 진단 중입니다.<br/>잠시만 기다려주세요.</Typography>
          <Box margin="auto" marginTop="100px">
            <Button variant="contained" color="light" onClick={eventCancel} sx={{width: "104px", height: "51px"}}>취소</Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}
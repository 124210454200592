import { Box, Typography } from "@mui/material";

/**
 * 진단 페이지 상단에 위치하는 단계 컴포넌트
 *
 * @param step
 * @param title
 * @param description
 * @param curStep 현재 단계
 * @returns {JSX.Element}
 * @constructor
 */
export default function StepItem({ step, title, description, curStep }) {

  const isCurStep = () => {
    return curStep === step;
  }

  return (
    <Box display="flex" flexDirection="row" gap="8px" margin={{xs: "0 10px", md: "24px"}}>
      <Box alignContent="center">
        <Typography
          width={{xs: "16px", md: "24px"}} height={{xs: "16px", md: "24px"}}
          borderRadius="32px" color={isCurStep() ? "white" : "#B4B4B4"}
          textAlign="center" fontSize={{xs: "10px", md: "16px"}}
          boxShadow={isCurStep() ? "0px 1px 1px 0px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)" : "none"}
          sx={{backgroundColor: isCurStep() ? "#020E82" : "#E0E0E0"}} >{step}</Typography>
      </Box>
      <Box>
        <Typography fontSize={{xs: "13px", md: "20px"}} fontWeight="700" color={isCurStep() ? "black" : "#B4B4B4"}>{title}</Typography>
        <Typography fontSize={{xs: "10px", md: "16px"}} color={isCurStep() ? "black" : "#B4B4B4"}>{description}</Typography>
      </Box>
    </Box>
  )
}
import { Box, Button, Chip, OutlinedInput, Table, TableCell, TableRow, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { Dimmed } from "../components/Dimmed";
import RestApi from "../../common/RestApi";
import html2canvas from "html2canvas";
import useAuth from "../../common/useAuth";
import { useSnackbar } from "notistack";
import CloseIconButton from "../components/CloseIconButton";
import { UIDataManager } from "../../common/UIDataManager";
import { UIDataContext } from "../../common/UIDataContext";

export default function SummaryModal() {

  const [loginUser, setLoginUser] = useState(null);

  const [educationList, setEducationList] = useState(null);

  const { setOpenTwoButtonPopup, isOpenSummaryModal, setOpenSummaryModal, setOpenShareEmailPopup } = useContext(UIDataContext);
  const { requestLoginUser, requestPatchLoginUser } = useAuth();

  const summaryRef = useRef();
  const modalRef = useRef();

  useEffect(() => {
    requestLoginUser((loginUser) => {
      setLoginUser(loginUser);
    });


    fetchEducationList();
    modalRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [isOpenSummaryModal]);

  const fetchEducationList = async () => {
    fetch('/data/education.json').then(res => res.json()).then(data => {
      console.log('>> fetch education data', data);
      setEducationList(data);
    });
  }

  const clickShareEmail = () => {
    UIDataManager.setShareEmailPopupInfo({
      url: "/diagnosis/summary/share",
      context: {
        education: UIDataManager.summaryModalInfo?.diagnosisEdu,
        companyName: loginUser?.companyName,
        employeeCount: UIDataManager.summaryModalInfo?.choice?.employee,
        business: UIDataManager.summaryModalInfo?.choice?.business,
        companyNumber: loginUser?.companyNumber,
        email: loginUser?.email || '-',
        phone: loginUser?.phone || '-',
      }
    });

    setOpenShareEmailPopup(true);
  }

  const getToday = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${year}년 ${month}월 ${day}일`
  }

  const Education = ({ name }) => {

    const isSelected = () => {
      return UIDataManager.summaryModalInfo?.diagnosisEdu.includes(name);
    }

    return (
      <Typography
        width="240px" height="23px"
        padding="15px" borderRadius="10px" border="1px solid #8F8F8F"
        textAlign="center" fontSize="16px"
        color={isSelected()?"#020E82":"#000000"}
        sx={{
          backgroundColor: isSelected() ? "rgba(2, 14, 130, 0.10)" : "white",
          border: "1px solid " + (isSelected()?"#020E82":"#6A6A6A")
        }}
      >
        {name}
      </Typography>
    )
  }

  return (
    <>
      <Dimmed show={isOpenSummaryModal}/>
      <Box ref={modalRef} id="resu01_01p" className="modal-wrap" top="40%" position="absolute" padding="30px"  sx={{backgroundColor: "white"}}>
        <CloseIconButton setShow={setOpenSummaryModal}/>

        <Box display="flex" width="920px" flexDirection="column" justifyContent="center" marginTop="20px">
          <Box ref={summaryRef}>
            <Typography fontSize="30px" textAlign="center">진단 결과를 공유해드립니다.</Typography>
            <Typography fontSize="20px" textAlign="center">아래에 표시된 법정의무교육을 이수해주세요. </Typography>

            <Box display="flex" flexDirection="column" padding="20px 30px" marginTop="20px">

              <Box display="flex" marginTop="42px" marginBottom="20px"><Typography color="white" fontSize="20px" fontWeight="700" padding="2px 20px" sx={{backgroundColor: "black"}}>이수하셔야 하는 법정의무교육</Typography></Box>
              <Box display="flex" flexWrap="wrap" gap="16px">
                {
                  educationList?.filter(item => UIDataManager.summaryModalInfo?.diagnosisEdu?.includes(item)).map((item, idx) => (
                    <Education key={idx} name={item} />
                  ))
                }
              </Box>

              <Box display="flex" marginTop="42px" marginBottom="20px"><Typography color="white" fontSize="20px" fontWeight="700" padding="2px 20px" sx={{backgroundColor: "black"}}>선택하신 항목 정보</Typography></Box>
              <Box display="flex" flexDirection="column" gap="2px">
                <Box display="flex">
                  <Typography fontSize="16px" fontWeight="700" width="140px">업종</Typography>
                  <Box display="flex" gap="8px">
                    <Chip label={UIDataManager.summaryModalInfo?.choice?.business} variant="outlined" />
                    { UIDataManager.summaryModalInfo?.choice?.subBusiness && <Chip label={UIDataManager.summaryModalInfo?.choice.subBusiness} variant="outlined"  /> }
                  </Box>
                </Box>
                <Box display="flex">
                  <Typography fontSize="16px" fontWeight="700" width="140px">근로자 수</Typography>
                  <Box display="flex" gap="8px"><Chip label={UIDataManager.summaryModalInfo?.choice?.employee} variant="outlined" /></Box>
                </Box>
                <Box display="flex">
                  <Typography fontSize="16px" fontWeight="700" width="140px">성비</Typography>
                  <Box display="flex" gap="8px"><Chip label={UIDataManager.summaryModalInfo?.choice?.gender} variant="outlined" /></Box>
                </Box>
                <Box display="flex">
                  <Typography fontSize="16px" fontWeight="700" width="140px">퇴직연금</Typography>
                  <Box display="flex" gap="8px"><Chip label={UIDataManager.summaryModalInfo?.choice?.pension} variant="outlined" /></Box>
                </Box>
                <Box display="flex">
                  <Typography fontSize="16px" fontWeight="700" width="140px">개인정보</Typography>
                  <Box display="flex" gap="8px"> <Chip label={UIDataManager.summaryModalInfo?.choice?.information} variant="outlined" /></Box>
                </Box>
                <Box display={UIDataManager.summaryModalInfo?.choice?.isHaveResponsiblePerson === undefined ? "none" : "flex"}>
                  <Typography fontSize="16px" fontWeight="700" width="140px">참고사항</Typography>
                  <Box display="flex" gap="8px">{ UIDataManager.summaryModalInfo?.choice?.isHaveResponsiblePerson && UIDataManager.summaryModalInfo?.choice?.isHaveResponsiblePerson.split('\n').map((item, idx) => (item === '' ? null : <Chip key={idx} label={item} variant="outlined" />))}</Box>
                </Box>
                <Box display={UIDataManager.summaryModalInfo?.choice?.etc === undefined ? "none" : "flex"}>
                  <Box><Typography fontSize="16px" fontWeight="700" width="140px" whiteSpace="nowrap">기타 해당사항</Typography></Box>
                  <Box display="flex" flexWrap="wrap" gap="8px">{ UIDataManager.summaryModalInfo?.choice?.etc && UIDataManager.summaryModalInfo?.choice?.etc.split('\n').map((item, idx) => (item === '' ? null : <Chip key={idx} label={item} variant="outlined" />))}</Box>
                </Box>
              </Box>

              <Box display="flex" marginTop="42px" marginBottom="20px"><Typography color="white" fontSize="20px" fontWeight="700" padding="2px 20px" sx={{backgroundColor: "black"}}>회사 정보</Typography></Box>
              <Box display="flex" flexDirection="row" justifyContent="space-between" >
                <Box display="flex" flexDirection="row" gap="32px" marginTop="16px">
                  <Box display="flex" flexDirection="column" gap="4px">
                    <Typography fontSize="16px">회사명</Typography>
                    <Typography fontSize="16px">사업자등록번호</Typography>
                    <Typography fontSize="16px">이메일 주소</Typography>
                    <Typography fontSize="16px">회사 연락처</Typography>
                  </Box>
                  <Box display="flex" flexDirection="column" gap="4px">
                    <Typography fontSize="16px">{loginUser?.companyName}</Typography>
                    <Typography fontSize="16px">{loginUser?.companyNumber}</Typography>
                    <Typography fontSize="16px">{loginUser?.companyEmail || '-'}</Typography>
                    <Typography fontSize="16px">{loginUser?.companyPhone || '-'}</Typography>
                  </Box>
                </Box>
                <Box>
                  <Box display="flex" justifyContent="flex-end">
                    <Box component="img" src="/images/icon.png" height="50px"/>
                  </Box>
                  <Typography textAlign="right" fontSize="16px" marginTop="10px">고객사에서 입력된 정보를 바탕으로<br/>AI가 분석한 법정의무교육 진단 결과입니다.</Typography>
                  <Typography textAlign="right" fontSize="20px" fontWeight="700" marginTop="10px">{getToday()}</Typography>
                  <Typography textAlign="right" fontSize="16px" marginTop="10px">아이두 법정의무교육 계산기</Typography>
                  <Typography textAlign="right" fontSize="14px" marginTop="5px">aidoo.kr  |  © 2024. Netstream. All Rights Reserved</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
            <Box height="1px" margin="20px 0" sx={{backgroundColor:"#0E0E0E"}}/>

            <Box display="flex" justifyContent="space-between">
              <Box><Typography fontWeight="700" fontSize="16px">본 진단결과는 법적인 효력을 가지고 있지 않습니다.<br/>더 자세한 정보는 상담을 통하여 정확히 알아보시는 것을 추천드립니다.</Typography></Box>
              <Box><Button onClick={clickShareEmail} variant="contained" sx={{padding: "15px 30px", fontSize: "16px", fontWeight: "700"}}>결과 공유하기</Button></Box>
            </Box>
        </Box>
      </Box>
    </>
  )
}
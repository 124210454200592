import RestApi from "./RestApi";

export default function useAuth() {

  const getFlagLogin = async () => {
    const data = localStorage.getItem('user');
    if(data !== undefined && data !== null) {
      const json = JSON.parse(data);
      console.log(1);
      return true;
    }

    try {
      const url = "/auth/profile";
      await RestApi.request(url);

      console.log(2);
      return true;
    } catch (e) {
      console.log('>> get logined user data FAIL ', e);
      console.log(3);
      return false;
    }
    console.log(4);
    return false;
  }

  const requestPatchLoginUser = (data, succFun, failFun) => {
    RestApi.request('/auth/profile', 'PATCH', data)
      .then(({ data }) => {
      console.log('>> update logined user data SUCCESS', data);
      window.localStorage.removeItem('user');

      requestLoginUser(succFun, failFun);
    }).catch((e) => {
      console.log('>> update logined user data FAIL ', e);

      if(failFun) failFun();
    });
  }

  const requestLoginUser = (succFun, failFun) => {
    const data = localStorage.getItem('user');
    if(data !== undefined && data !== null) {
      if(succFun) succFun(JSON.parse(data));
      return;
    }

    const url = "/auth/profile";
    RestApi.request(url).then(({ data }) => {
      console.log('>> get logined user data SUCCESS', data);
      window.localStorage.setItem("user", JSON.stringify(data));

      if(succFun) succFun(data);
    }).catch((e) => {
      console.log('>> get logined user data FAIL ', e);

      if(failFun) failFun();
    });
  }

  const requestIsLogin = (succFun, failFun) => {
    getFlagLogin().then((is) => {
      if(is) {
        if(succFun) succFun();
      } else {
        if(failFun) failFun();
      }
    }).catch((err) => {
      if(failFun) failFun();
    });
  }

  const login = async (userId, password) => {
    try {
      const url = "/auth/login";
      const method = "POST";
      const body = {userId, password};

      const { data } = await RestApi.request(url, method, body);

      if (data?.accessToken) {
        console.log('>> LOGIN SUCCESS');
        window.localStorage.setItem("access_token", data.accessToken);
      }
    } catch(e) {
      console.log('>> LOGIN FAIL ', e);
      throw e;
    }
  }

  const logout = (succFun, failFun) => {
    const url = "/auth/logout";
    const method = "POST";

    RestApi.request(url, method)
    .then(() => {
      console.log('SUCCESS :: Logout');
      window.localStorage.removeItem("access_token");
      window.localStorage.removeItem("user");

      if(succFun) succFun();
    })
    .catch((e) => {
      console.log('ERROR :: Logout ', e);
      window.localStorage.removeItem("access_token");
      window.localStorage.removeItem("user");

      if(failFun) failFun();
    });
  }

  return {
    login, logout, getFlagLogin, requestIsLogin, requestLoginUser, requestPatchLoginUser
  }
}

import Footer from "./ui/components/Footer";
import { SnackbarProvider, useSnackbar } from 'notistack';
import { createTheme, ThemeProvider } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ScrollTopPageMove from "./common/ScrollTopPageMove";
import { Outlet, useLocation } from "react-router-dom";
import TopButton from "./ui/components/TopButton";
import MediaQuery, { useMediaQuery } from "react-responsive";
import { UIDataContext } from "./common/UIDataContext";
import TwoButtonPopup from "./ui/popup/TwoButtonPopup";
import { CookiesProvider } from "react-cookie";
import LoginModal from "./ui/login/LoginModal";
import RegisterModal from "./ui/register/RegisterModal";
import PartnerInquiryModal from "./ui/modal/PartnerInquiryModal";
import OneButtonPopup from "./ui/popup/OneButtonPopup";
import SummaryModal from "./ui/modal/SummaryModal";
import MobileBlock from "./ui/components/MobileBlock";
import ShareEmailPopup from "./ui/popup/ShareEmailPopup";
import BetaWarningPopup from "./ui/popup/BetaWarningPopup";

function App() {
  const [isOpenOneButtonPopup, setOpenOneButtonPopup] = useState(false);
  const [isOpenTwoButtonPopup, setOpenTwoButtonPopup] = useState(false);
  const [isOpenLoginModal, setOpenLoginModal] = useState(false);
  const [isOpenRegisterModal, setOpenRegisterModal] = useState(false);
  const [isOpenPartnerModal, setOpenPartnerModal] = useState(false);
  const [isOpenSummaryModal, setOpenSummaryModal] = useState(false);
  const [isOpenShareEmailPopup, setOpenShareEmailPopup] = useState(false);
  const [isOpenBetaWarningPopup, setOpenBetaWarningPopup] = useState(false);

  const theme = createTheme({
    typography: {
      fontFamily: '"Noto Sans KR", serif',
    },
    palette: {
      primary: {
        main: '#020E82'
      },
      light: {
        main: '#FFFFFF',
        contrastText: '#020E82'
      }
    }
  });

  const mediaXs = useMediaQuery({ minWidth: 0});
  const mediaMd = useMediaQuery({ minWidth: 900});

  const { pathname } = useLocation();

  // 페이지 변경 할 떄 마다 스크롤을 탑으로 이동
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(pathname.split('/'));
  }, [pathname]);

  return (
    <div className="App">
      <CookiesProvider>
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={5}>
            <UIDataContext.Provider
              value={{
                isOpenOneButtonPopup, setOpenOneButtonPopup,
                isOpenTwoButtonPopup, setOpenTwoButtonPopup,
                isOpenLoginModal, setOpenLoginModal,
                isOpenRegisterModal, setOpenRegisterModal,
                isOpenPartnerModal, setOpenPartnerModal,
                isOpenSummaryModal, setOpenSummaryModal,
                isOpenShareEmailPopup, setOpenShareEmailPopup,
                isOpenBetaWarningPopup, setOpenBetaWarningPopup
              }}>

              <Outlet  />
              <Footer/>
              { mediaMd && <TopButton /> }

              { isOpenOneButtonPopup && <OneButtonPopup/> }
              { isOpenTwoButtonPopup && <TwoButtonPopup/>}
              { isOpenLoginModal && <LoginModal />}
              { isOpenRegisterModal && <RegisterModal /> }
              { isOpenPartnerModal && <PartnerInquiryModal />}
              { isOpenSummaryModal && <SummaryModal />}
              { isOpenShareEmailPopup && <ShareEmailPopup /> }
              { isOpenBetaWarningPopup && <BetaWarningPopup/> }

            </UIDataContext.Provider>
          </SnackbarProvider>
        </ThemeProvider>
      </CookiesProvider>
    </div>
  );
}

export default App;

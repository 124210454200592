import {
  Box,
  Button, TextField,
  Typography
} from "@mui/material";
import { useContext, useState } from "react";
import { UIDataContext } from "../../common/UIDataContext";
import { UIDataManager } from "../../common/UIDataManager";
import { useSnackbar } from "notistack";
import RestApi from "../../common/RestApi";
import useAuth from "../../common/useAuth";

export default function ShareEmailPopup() {
  const { setOpenShareEmailPopup, setOpenLoginModal } = useContext(UIDataContext);
  const { enqueueSnackbar } = useSnackbar();
  const { requestLoginUser } = useAuth();

  const [email, setEmail] = useState('');

  const getToday = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${year}년 ${month}월 ${day}일`
  }

  const clickShare = async () => {
    if(email === "" || validateEmail() !== "") {
      return;
    }

    try {
      const result = await RestApi.request(UIDataManager.shareEmailPopupInfo?.url, 'POST', {
        receiver: email,
        context: {
          ...UIDataManager.shareEmailPopupInfo?.context,
          date: getToday(),
        }
      });
      enqueueSnackbar("입력하신 이메일로 진단 결과를 전송하였습니다.", {variant: "success"});
    } catch (e) {
      console.log("ERROR :: send email diagnosis summary ", e);
      enqueueSnackbar("일시적인 오류로 진단 결과 전송에 실패하였습니다. 잠시후 다시 시도해주세요.", {variant: "error"});
    }

    setOpenShareEmailPopup(false);
  }

  const validateEmail = () => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if(email === "") {
      return '';
    }
    else if(!regex.test(email)) {
      return '올바른 이메일을 입력해주세요. ';
    }
    else {
      return '';
    }
  }


  return (
    <>
      <Box position="absolute" zIndex="998" left="0" width="100%" height="100%" sx={{backgroundColor: "rgba(0, 0, 0, 0.60)"}}/>
      <Box className="modal-wrap box" zIndex="999" top={{xs: "30%", md: "20%"}} width="auto" padding="23px 24px 13px 24px" borderRadius="5px">
        <Box display="flex" flexDirection="column" gap="10px">
          <Typography fontSize={{xs: "18px", md: "20px"}}>결과 공유하기</Typography>
          <Typography fontSize={{xs: "14px", md: "16px"}} sx={{whiteSpace: "pre-line", wordBreak: "keep-all"}}>진단 결과를 수신할 이메일 주소를 입력해주세요.</Typography>

          <TextField type="email" value={email} onChange={(e) => setEmail(e.target.value)}
                     error={validateEmail() !== ""} helperText={validateEmail()}
                     placeholder="이메일 주소 입력" sx={{width: {xs: "300px", md: '325px'}, '.MuiInputBase-input': { fontSize: {xs: "14px", md: "16px"} }}}/>

          <Box display="flex" justifyContent="flex-end" gap="8px" marginTop="15px">
            <Button sx={{fontSize: {xs: "14px", md: "16px"}}} variant="outlined" onClick={() => setOpenShareEmailPopup(false)}>취소</Button>
            <Button sx={{fontSize: {xs: "14px", md: "16px"}}} variant="contained" onClick={clickShare}>공유하기</Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}
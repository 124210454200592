import { Helmet } from "react-helmet-async";
import { useEffect } from "react";

export default function Meta({title, url}) {

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:url" content={`https://aidoo.kr${url}`} />
    </Helmet>
  )
}
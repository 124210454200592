import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton, InputAdornment,
  OutlinedInput,
  TextField,
  Typography
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import RestApi from "../../common/RestApi";
import { useSnackbar } from "notistack";
import StepItem from "./components/StepItem";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { UIDataContext } from "../../common/UIDataContext";
import { useNavigate } from "react-router-dom";
import { UIDataManager } from "../../common/UIDataManager";
import { useMediaQuery } from "react-responsive";

export default function RegisterForm() {
  const [curStep, setCurStep] = useState(1); // 단계 ( 1 ~ 2 )

  const [companyNumber, setCompanyNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordCheck, setPasswordCheck] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [companyPhone, setCompanyPhone] = useState('');

  const [errCompanyNumber, setErrCompanyNumber] = useState('');
  const [errCompanyName, setErrCompanyName]   = useState('');
  const [errPassword, setErrPassword] = useState('');
  const [errPasswordCheck, setErrPasswordCheck] = useState('');
  const [errCompanyEmail, setErrCompanyEmail] = useState('');
  const [errCompanyPhone, setErrCompanyPhone] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordCheck, setShowPasswordCheck] = useState(false);

  const refCompanyNumber = useRef();
  const refCompanyName = useRef();
  const refPassword = useRef();
  const refPasswordCheck = useRef();
  const refCompanyEmail = useRef();
  const refCompanyPhone = useRef();

  const [isCheckCompanyNumber, setCheckCompanyNumber] = useState(false); // 사업자등록번호 중복 체크 여부

  const [checkBoxItems, setCheckBoxItems] = useState([]); // 약관 동의 리스트

  const { isOpenRegisterModal, setOpenRegisterModal, setOpenLoginModal, setOpenTwoButtonPopup } = useContext(UIDataContext);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const mediaMd = useMediaQuery({ minWidth: 900});

  const clickRegister = async () => {
    if(!validateTextField()) {
      setCurStep(1);
    }
    else if(!validateTerms()) {
      setCurStep(2);
    }
    else {
      try {
        const body = {
          companyNumber: companyNumber,
          companyName: companyName,
          password: password,
          companyEmail: companyEmail || null,
          companyPhone: companyPhone || null,
          isAgreeEmail: 0, // checkBoxItems.includes(3) ? 0 : 1,
          isAgreeAd: 0, // checkBoxItems.includes(4) ? 0 : 1
        };
        await RestApi.request('/auth/register', "POST", body);

        enqueueSnackbar("아이두의 회원이 되신 것을 환영합니다.", {variant: "success"});
        setCurStep(1);
        setCompanyNumber('');
        setCompanyName('');
        setPassword('');
        setPasswordCheck('');
        setCompanyEmail('');
        setCompanyPhone('');
        setCheckBoxItems([]);

        if (isOpenRegisterModal) {
          setOpenRegisterModal(false);
          setOpenLoginModal(true);
        }
        else {
          navigate('/login');
        }
      } catch (e) {
        console.log("ERROR :: register error ", e);
      }
    }
  }

  const clickPrevent = () => {
    if (curStep === 2) {
      setCurStep(1);

      if(!isOpenRegisterModal) {
        window.scrollTo({top: 0, behavior: "smooth"});
      }
    }
  }

  const clickNext = () => {
    if(curStep === 1) {
      if(validateTextField()) {
        setCurStep(2);

        if(!isOpenRegisterModal) {
          window.scrollTo({top: 0, behavior: "smooth"});
        }
      }
    }
  }

  const clickLogin = () => {
    if(isOpenRegisterModal) {
      UIDataManager.setTwoButtonPopupInfo({
        content:"이 페이지를 벗어나면 입력하셨던 내용이 모두 사라질 수 있습니다.\n페이지를 이동하시겠습니까?",
        btn1Text: "이동하기",
        btn2Text: "계속 가입하기",
        btn1Fun: () => {setOpenLoginModal(true); setOpenRegisterModal(false);}
      });
      setOpenTwoButtonPopup(true);
    }
    else {
      navigate('/login');
    }
  }

  const validateTextField = () => {
    // 공백 여부
    if(companyNumber === "") {
      setErrCompanyNumber("사업자등록번호를 입력해주세요.");
      refCompanyNumber?.current.scrollIntoView({ behavior: 'smooth' });
    }
    else if(companyName === "") {
      setErrCompanyName("회사명을 입력해주세요.");
      refCompanyName?.current.scrollIntoView({ behavior: 'smooth' });
    }
    else if(password === "") {
      setErrPassword("비밀번호를 입력해주세요. ");
      refPassword?.current.scrollIntoView({ behavior: 'smooth' });
    }
    else if(passwordCheck === "") {
      setErrPasswordCheck("비밀번호 확인을 입력해주세요. ");
      refPasswordCheck?.current.scrollIntoView({ behavior: 'smooth' });
    }
    else if(companyPhone === "") {
      setErrCompanyPhone("전화번호를 입력해주세요. ");
      refCompanyPhone?.current.scrollIntoView({ behavior: 'smooth' });
    }
    // 에러 메시지가 있을 경우
    else if(errCompanyNumber !== "") {
      refCompanyNumber?.current.scrollIntoView({ behavior: 'smooth' });
    }
    else if(errCompanyName !== "") {
      refCompanyName?.current.scrollIntoView({ behavior: 'smooth' });
    }
    else if(errPassword !== "") {
      setPassword?.current.scrollIntoView({ behavior: 'smooth' });
    }
    else if(errPasswordCheck !== "") {
      refPasswordCheck?.current.scrollIntoView({ behavior: 'smooth' });
    }
    else if(errCompanyEmail !== "") {
      refCompanyEmail?.current.scrollIntoView({ behavior: 'smooth' });
    }
    else if(errCompanyPhone !== "") {
      refCompanyPhone?.current.scrollIntoView({ behavior: 'smooth' });
    }
    // 사업자등록번호 중복 체크 여부
    else if(!isCheckCompanyNumber) {
      setErrCompanyNumber("사업자등록번호 중복 여부를 확인해주세요.");
      refCompanyNumber?.current.scrollIntoView({ behavior: 'smooth' });
    }
    else {
      return true;
    }
    return false;
  }

  const validateTerms = () => {
    if(!(checkBoxItems.includes(0) && checkBoxItems.includes(1))) {
      enqueueSnackbar("필수 약관에 모두 동의해주세요.", {variant: "error"});
    } else {
      return true;
    }
    return false;
  }

  const clickCheckCompanyNumber = async () => {
    const regex = /^[0-9]+$/;

    if(companyNumber === "") {
      enqueueSnackbar("사업자등록번호를 입력해주세요.", {variant: "error"});
    }
    else if(!regex.test(companyNumber)) {
      setErrCompanyNumber('사업자등록번호는 숫자만 사용 가능합니다.');
    }
    else if(companyNumber.length !== 10) {
      setErrCompanyNumber('사업자등록번호 10자리를 입력해주세요.');
    }
    else {
      try {
        await RestApi.request('/auth/validate/companyNumber?companyNumber='+companyNumber+'&same=1', 'GET');
        enqueueSnackbar("사용 가능한 사업자등록번호입니다.", {variant: "success"});
        setErrCompanyNumber('');
        setCheckCompanyNumber(true);
        return;
      } catch (e) {
        if(e.error === "Bad Request") {
          setErrCompanyNumber("아이두에 가입된 사업자등록번호입니다. ");
        } else if(e.error === "Unauthorized") {
          setErrCompanyNumber("존재하지 않는 사업자등록번호입니다.");
        }
      }
    }
    setCheckCompanyNumber(false);
  }

  const clickCheckBoxAll = (e) => {
      if (e.target.checked) {
        setCheckBoxItems([0, 1]);
      } else {
        setCheckBoxItems([]);
      }
  }

  const clickCheckBoxSingle = (e) => {
    const id = e.target.id;

    if(e.target.checked) {
        setCheckBoxItems(prev => [...prev, +id]);
    } else {
      setCheckBoxItems(checkBoxItems.filter((el) => el !== +id));
    }
  }

  const changeCompanyNumber = (e) => {
    const value = e.target.value.substring(0, 10);
    setCompanyNumber(value);
    setCheckCompanyNumber(false);
    validateCompanyNumber(value);
  }
  const changeCompanyName = (e) => {
    setCompanyName(e.target.value);
    setErrCompanyName('');
  }
  const changePassword = (e) => {
    setPassword(e.target.value);
    validatePassword(e.target.value);
    validatePasswordCheck(e.target.value, passwordCheck);
  }
  const changePasswordCheck = (e) => {
    setPasswordCheck(e.target.value);
    validatePassword(password);
    validatePasswordCheck(password, e.target.value);
  }
  const changeCompanyEmail = (e) => {
    setCompanyEmail(e.target.value);
    validateCompanyEmail(e.target.value);
  }
  const changeCompanyPhone = (e) => {
    setCompanyPhone(e.target.value);
    validateCompanyPhone(e.target.value);
  }

  const validateCompanyNumber = (companyNumber) => {
    const regex = /^[0-9]+$/;

    if(companyNumber === '') {
      setErrCompanyNumber('');
    }
    else if(!regex.test(companyNumber)) {
      setErrCompanyNumber('사업자등록번호는 숫자만 사용 가능합니다.');
    }
    else {
      setErrCompanyNumber('');
    }
  }

  const validatePassword = (password) => {
    const regex1 = /^[a-zA-Z0-9$!%*?&]+$/; // 특수문자가 포함하는 지 여부
    const regex2 = /^(?=.*[A-Za-z])(?=.*[0-9])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z0-9\d@$!%*?&]{8,16}$/; // 비밀번호 정규식

    if(password === '') {
      setErrPassword('');
    }
    else if(!regex1.test(password)) {
      setErrPassword("사용 가능한 특수문자는 $!%*?& 입니다.");
    }
    else if(!regex2.test(password)) {
      setErrPassword("8~16자, 영문과 특수문자를 포함해야 합니다.");
    }
    else {
      setErrPassword("");
    }
  }

  const validatePasswordCheck = (password, passwordCheck) => {
    if(passwordCheck === "" || password === "") {
      setErrPasswordCheck("");
    }
    else if(password !== passwordCheck) {
      setErrPasswordCheck("입력한 비밀번호가 일치하지 않습니다. ");
    }
    else {
      setErrPasswordCheck("");
    }
  }

  const validateCompanyEmail = (companyEmail) => {
    const regex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

    if(companyEmail === '') {
      setErrCompanyEmail("")
    }
    else if(!regex.test(companyEmail)) {
      setErrCompanyEmail("올바른 이메일을 입력해주세요.")
    }
    else {
      setErrCompanyEmail("");
    }
  }

  const validateCompanyPhone = (companyPhone) => {
    const regex = /^[0-9]{9,11}$/;

    if(companyPhone === '') {
      setErrCompanyPhone('');
    }
    else if(!regex.test(companyPhone)) {
      setErrCompanyPhone("연락처는 9~11자리 숫자만 사용 가능합니다.");
    }
    else {
      setErrCompanyPhone("");
    }
  }

  return (
    <>
      <Typography fontSize={{xs: "20px", md: "40px"}} fontWeight="700" textAlign="center" color="#0E0E0E">회원가입</Typography>

      <Box display="flex" justifyContent="flex-start" marginTop="50px" marginBottom={{xs: "20px", md: "65px"}}>
        <StepItem click={clickPrevent} curStep={curStep} step={1} title="기본 정보 입력" description="귀사의 정보를 입력해주세요."/>
        <StepItem click={clickNext} curStep={curStep} step={2} title="약관 동의하기" description="약관을 확인하신 후 동의해주세요."/>
      </Box>

      {/* STEP 1 */}
      <Box display={curStep===1?"flex":"none"} flexDirection="column" justifyContent="space-between">
        <Box display="flex" flexDirection="column" gap={{xs: "10px", md: "32px"}} marginLeft="10px">
          {/* 사업자등록번호 */}
          <Box display="flex" flexWrap="wrap" flexDirection={{xs: "column", md: "row"}} alignItems={{md: "center"}} gap={{xs: "8px", md: "20px"}} ref={refCompanyNumber}>
            <Typography width="125px" fontSize={{xs: "14px", md: "16px"}} fontWeight="700">사업자등록번호</Typography>

            <Box display="flex" flexDirection="row" gap={{xs: "8px", md: "20px"}} >
              <TextField
                type="number"
                value={companyNumber} onChange={changeCompanyNumber}
                error={errCompanyNumber !== ''} helperText={errCompanyNumber}
                placeholder="사업자등록번호를 -없이 입력해주세요."
                FormHelperTextProps={{ sx: { fontSize: {xs: "12px", md: "16px"} }}}
                size={mediaMd ? "medium" : "small"}
                fullWidth
                sx={{width: {md: "410px"}, fontSize: {xs: "12px", md: "16px"}, '.MuiInputBase-input': { fontSize: {xs: "12px", md: "16px"} }}}/>
              <Box>
                <Button variant="outlined" onClick={clickCheckCompanyNumber} sx={{ height: {md: "56px"}, fontSize: {xs: "12px", md: "16px"}, color: "#0E0E0E", border: "1px solid #8F8F8F", whiteSpace: "nowrap"}}>중복 확인</Button>
              </Box>
            </Box>
          </Box>
          {/* 회사명 */}
          <Box display="flex" flexWrap="wrap" flexDirection={{xs: "column", md: "row"}} alignItems={{md: "center"}} gap={{xs: "8px", md: "20px"}} ref={refCompanyName}>
            <Typography width="125px" fontSize={{xs: "14px", md: "16px"}} fontWeight="700">회사명</Typography>
            <TextField
              value={companyName} onChange={changeCompanyName}
              error={errCompanyName !== ''} helperText={errCompanyName}
              fullWidth
              size={mediaMd ? "medium" : "small"}
              FormHelperTextProps={{ sx: { fontSize: {xs: "12px", md: "16px"} }}}
              placeholder="기업의 상호명을 입력해주세요." sx={{width: {md: '410px'}, '.MuiInputBase-input': { fontSize: {xs: "12px", md: "16px"} }}}/>
          </Box>
          {/* 비밀번호 */}
          <Box display="flex" flexWrap="wrap" flexDirection={{xs: "column", md: "row"}} alignItems={{md: "center"}} gap={{xs: "8px", md: "20px"}} ref={refPassword}>
            <Typography width="125px" fontSize={{xs: "14px", md: "16px"}} fontWeight="700">비밀번호 입력</Typography>
            <TextField
              type={showPassword ? 'text' : 'password'}
              name="password"
              value={password}
              error={errPassword !== ""}
              helperText={errPassword}
              placeholder="대소문자와 특수문자를 포함한 8~16자리 비밀번호"
              onChange={changePassword}
              size={mediaMd ? "medium" : "small"}
              FormHelperTextProps={{ sx: { fontSize: {xs: "12px", md: "16px"} }}}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className="icon"
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((show) => !show)}
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff sx={{width: {xs: "20px"}}} /> : <Visibility sx={{width: {xs: "20px"}}} />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              sx={{width: {md: '410px'}, '.MuiInputBase-input': { fontSize: {xs: "12px", md: "16px"} }}}
            />
          </Box>
          {/* 비밀번호 확인 */}
          <Box display="flex" flexWrap="wrap" flexDirection={{xs: "column", md: "row"}} alignItems={{md: "center"}} gap={{xs: "8px", md: "20px"}} ref={refPasswordCheck}>
            <Typography width="125px" fontSize={{xs: "14px", md: "16px"}} fontWeight="700">비밀번호 재입력</Typography>
            <TextField
              type={showPasswordCheck ? 'text' : 'password'}
              name="password"
              value={passwordCheck}
              error={errPasswordCheck !== ""}
              helperText={errPasswordCheck}
              placeholder="확인을 위한 비밀번호를 입력해주세요"
              onChange={changePasswordCheck}
              size={mediaMd ? "medium" : "small"}
              FormHelperTextProps={{ sx: { fontSize: {xs: "12px", md: "16px"} }}}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className="icon"
                      aria-label="toggle password visibility"
                      onClick={() => setShowPasswordCheck((show) => !show)}
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                    >
                      {showPasswordCheck ? <VisibilityOff sx={{width: {xs: "20px"}}}/> : <Visibility sx={{width: {xs: "20px"}}}/>}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              sx={{width: {md: '410px'}, '.MuiInputBase-input': { fontSize: {xs: "12px", md: "16px"} }}}
            />
          </Box>
          {/* 회사 연락처 */}
          <Box display="flex" flexWrap="wrap" flexDirection={{xs: "column", md: "row"}} alignItems={{md: "center"}} gap={{xs: "8px", md: "20px"}} ref={refCompanyPhone}>
            <Typography width="125px" fontSize={{xs: "14px", md: "16px"}} fontWeight="700">회사 연락처</Typography>
            <TextField
              type="number"
              value={companyPhone} onChange={changeCompanyPhone}
              error={errCompanyPhone !== ''} helperText={errCompanyPhone}
              size={mediaMd ? "medium" : "small"}
              FormHelperTextProps={{ sx: { fontSize: {xs: "12px", md: "16px"} }}}
              placeholder="02(0)12341234 (- 제외)" sx={{width: {md: '410px'}, '.MuiInputBase-input': { fontSize: {xs: "12px", md: "16px"} },}}/>
          </Box>
          {/* 회사 이메일 */}
          <Box display="flex" flexWrap="wrap" flexDirection={{xs: "column", md: "row"}} alignItems={{md: "center"}} gap={{xs: "8px", md: "20px"}} ref={refCompanyEmail}>
            <Typography  width="125px" fontSize={{xs: "14px", md: "16px"}} fontWeight="700">(선택) 회사 이메일</Typography>
            <TextField
              value={companyEmail} onChange={changeCompanyEmail}
              error={errCompanyEmail !== ''} helperText={errCompanyEmail}
              size={mediaMd ? "medium" : "small"}
              FormHelperTextProps={{ sx: { fontSize: {xs: "12px", md: "16px"} }}}
              placeholder="이메일 주소를 입력해주세요." sx={{width: {md: '410px'}, '.MuiInputBase-input': { fontSize: {xs: "12px", md: "16px"} },}}/>
          </Box>
        </Box>

        <Box marginTop="50px">
          <Button fullWidth variant="contained" size={mediaMd ? "large" : "small"} onClick={clickNext}>다음</Button>
          <Box height="10px"/>
          <Button fullWidth variant="outlined" size={mediaMd ? "large" : "small"} onClick={clickLogin}>로그인</Button>
        </Box>
      </Box>

      {/* STEP 2 */}
      <Box display={curStep===2?"flex":"none"} flexDirection="column">
        <Box display="flex" flexDirection="column" width={{md: "640px"}} marginLeft="20px" marginRight="20px" marginBottom="40px">

          <FormControlLabel label={<Typography fontSize={{xs: "16px", md: "20px"}}>모두 동의하기</Typography>}
                            control={<Checkbox size="small" onChange={clickCheckBoxAll} checked={checkBoxItems.length === 2}/>} sx={{marginBottom:"6px"}}/>

          <Box height="1px" marginTop="16px" marginBottom="16px" sx={{backgroundColor: "#454545"}}/>

          <Box display="flex" justifyContent="space-between" alignItems="center">
            <FormControlLabel label={<Typography fontSize={{xs: "16px", md: "20px"}}>(필수) 이용약관 동의</Typography>}
                              control={<Checkbox id="0" size="small" checked={checkBoxItems.includes(0)} onChange={clickCheckBoxSingle} />}/>
            <Typography fontSize="16px" sx={{ textDecoration: "underline", cursor: "pointer" }}
                        onClick={() => window.open('https://cdn2.netstream.co.kr/aidoo/aidoo-terms-of-use-2024-1.0.0.pdf')}>전체보기</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <FormControlLabel label={<Typography fontSize={{xs: "16px", md: "20px"}}>(필수) 개인정보 수집 및 이용 동의</Typography>}
                              control={<Checkbox id="1" size="small" checked={checkBoxItems.includes(1)} onChange={clickCheckBoxSingle}/>}/>
            <Typography fontSize="16px" sx={{ textDecoration: "underline", cursor: "pointer" }}
                        onClick={() => window.open('https://cdn2.netstream.co.kr/aidoo/aidoo-privacy-policy-2024-1.0.0.pdf')}>전체보기</Typography>
          </Box>
          {/*<Box display="flex" justifyContent="space-between">*/}
          {/*  <FormControlLabel label={<span style={{fontSize: "16px"}}>(필수) 개인정보 제3자 제공 동의</span>}*/}
          {/*                    control={<Checkbox id="2" size="small" checked={checkBoxItems.includes(2)} onChange={clickCheckBoxSingle}/>}/>*/}
          {/*  <Typography fontSize="16px" sx={{ textDecoration: "underline" }}>전체보기</Typography>*/}
          {/*</Box>*/}
          {/*<Box display="flex" justifyContent="space-between">*/}
          {/*  <FormControlLabel label={<span style={{fontSize: "16px"}}>(선택) 회사, hr 관련 소식을 위한 메일링 서비스 가입(무료)</span>}*/}
          {/*                    control={<Checkbox id="3" size="small" checked={checkBoxItems.includes(3)} onChange={clickCheckBoxSingle}/>}/>*/}
          {/*  <Typography fontSize="16px" sx={{ textDecoration: "underline" }}>전체보기</Typography>*/}
          {/*</Box>*/}
          {/*<Box display="flex" justifyContent="space-between">*/}
          {/*  <FormControlLabel label={<span style={{fontSize: "16px"}}>(선택) 광고성 정보 제공 수신 동의</span>}*/}
          {/*                    control={<Checkbox id="4" size="small" checked={checkBoxItems.includes(4)} onChange={clickCheckBoxSingle}/>}/>*/}
          {/*  <Typography fontSize="16px" sx={{ textDecoration: "underline" }}>전체보기</Typography>*/}
          {/*</Box>*/}
        </Box>

        <Box display="flex" flexDirection="column" gap="16px" marginTop={{md: "20px"}}>
          <Button fullWidth variant="contained" sx={{height: "50px", fontSize: {xs: "16px", md: "16px"}}} onClick={clickRegister}>회원가입</Button>
        </Box>
      </Box>

    </>
  )
}
import { Dimmed } from "../components/Dimmed";
import { Box, Button, Dialog, Snackbar, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import RestApi from "../../common/RestApi";
import { SnackbarProvider, useSnackbar } from 'notistack';
import Modal from "../components/Modal";
import CloseIconButton from "../components/CloseIconButton";
import RegisterForm from "../register/RegisterForm";
import { UIDataContext } from "../../common/UIDataContext";
import { useMediaQuery } from "react-responsive";

export default function PartnerInquiryModal({}) {

  const [companyName, setCompanyName] = useState('');
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [content, setContent] = useState('');

  const { isOpenPartnerModal, setOpenPartnerModal } = useContext(UIDataContext);
  const { enqueueSnackbar } = useSnackbar();


  const mediaMd = useMediaQuery({ minWidth: 900});

  useEffect(() => {
    setCompanyName('');
    setName('');
    setTitle('');
    setEmail('');
    setPhone('');
    setContent('');
  }, [isOpenPartnerModal]);

  const clickEnter = async () => {
    if(companyName === "") {
      enqueueSnackbar("회사명을 입력해주세요.", {variant: "error"});
    }
    else if(name === "") {
      enqueueSnackbar("이름을 입력해주세요.", {variant: "error"});
    }
    else if(title === "") {
      enqueueSnackbar("직함을 입력해주세요.", {variant: "error"});
    }
    else if(email === "") {
      enqueueSnackbar("이메일을 입력해주세요.", {variant: "error"});
    }
    else if(phone === "") {
      enqueueSnackbar("전화번호를 입력해주세요.", {variant: "error"});
    }
    else if(content === "") {
      enqueueSnackbar("문의하실 내용을 입력해주세요.", {variant: "error"});
    }
    else {
      const url = "/inquiry/partner";
      const method = "POST";
      const body = { companyName, name, title, email, phone, content };

      try {
        const { data } = await RestApi.request(url, method, body);

        enqueueSnackbar("아이두에 파트너 문의가 완료되었습니다. ", {variant: "success"});
      } catch(e) {
        console.log('::: ERROR SAVE Partner Inquiry ', e);
      }

      setOpenPartnerModal(false);
    }
  }

  return (
    <>
      <Dimmed show={true} zIndex={200}/>
      <Box className="modal-wrap box" width={{xs: "100%", md: "auto"}} height={{xs: "100%", md: "auto"}} top="50%" sx={{zIndex: 201, padding: {xs: "20px 15px", md: "5px 10px"}}}>
        <CloseIconButton setShow={setOpenPartnerModal} />

        <Box display="flex" gap="10px" flexDirection="column" padding="20px">
          <Box display="flex" justifyContent="center" margin={{xs: "20px 0", md: "auto"}}><Box component="img" height="46px" src="/images/icon.png"/></Box>

          <Typography fontSize={{xs: "18px", md: "20px"}} textAlign="center" marginTop="10px">아이두와 함께 성장할 파트너를 기다립니다.</Typography>

          <Box display="flex" flexDirection="column" gap="15px" margin="10px 0">
            {/* 회사명 */}
            <TextField type="text" size="medium" value={companyName} onChange={(e) => setCompanyName(e.target.value)}
                       placeholder="회사명을 입력해주세요." variant="outlined" fullWidth sx={{'.MuiInputBase-input': { fontSize: { xs: "14px", md: '16px'} }}}/>
            <Box display="flex" gap="10px">
              {/* 이름 */}
              <TextField type="text" value={name} size="medium" onChange={(e) => setName(e.target.value)}
                         placeholder="이름을 입력해주세요." variant="outlined" fullWidth sx={{'.MuiInputBase-input': { fontSize: { xs: "14px", md: '16px'} }}}/>
              {/* 직함 */}
              <TextField type="text" value={title} size="medium" onChange={(e) => setTitle(e.target.value)}
                         placeholder="직함을 입력해주세요." variant="outlined" fullWidth sx={{'.MuiInputBase-input': { fontSize: { xs: "14px", md: '16px'} }}}/>
            </Box>
            {/* 이메일 */}
            <TextField type="text" value={email} size="medium" onChange={(e) => setEmail(e.target.value)}
                       placeholder="이메일을 입력해주세요." variant="outlined" fullWidth sx={{'.MuiInputBase-input': { fontSize: { xs: "14px", md: '16px'} }}}/>
            {/* 전화번호 */}
            <TextField type="text" value={phone} size="medium" onChange={(e) => setPhone(e.target.value)}
                       placeholder="전화번호를 입력해주세요." variant="outlined" fullWidth sx={{'.MuiInputBase-input': { fontSize: { xs: "14px", md: '16px'} }}}/>
            {/* 문의 내용 */}
            <TextField type="text" value={content} size="medium" onChange={(e) => setContent(e.target.value)}
                       rows={3} placeholder="문의하실 내용을 입력해주세요." multiline variant="outlined" fullWidth sx={{'.MuiInputBase-input': { fontSize: {xs: "14px", md: '16px'} }}}/>
          </Box>

          <Button fullWidth variant="outlined" onClick={clickEnter} sx={{ height: "55px" }}>문의하기</Button>
        </Box>
      </Box>
    </>
  )
}
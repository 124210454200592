import { Box, Button, Dialog, Typography } from "@mui/material";
import BuildIcon from '@mui/icons-material/Build';
import CloseIconButton from "../components/CloseIconButton";
import { Dimmed } from "../components/Dimmed";
import Modal from "../components/Modal";
import { useContext } from "react";
import { UIDataContext } from "../../common/UIDataContext";

export default function BetaWarningPopup() {

  const { isOpenBetaWarningPopup, setOpenBetaWarningPopup } = useContext(UIDataContext);

  return (
    <>
      <Box position="absolute" zIndex="998" left="0" width="100%" height="100%" sx={{backgroundColor: "rgba(0, 0, 0, 0.60)"}}/>
      <Box className="modal-wrap box" zIndex="999" top={{xs: "25%", md: "20%"}} minWidth={{xs: "350px", md: "400px"}} maxWidth={{md: "450px"}} padding="23px 24px 13px 24px" borderRadius="5px">
        <Box display="flex" justifyContent="center" flexDirection="column" gap="16px">
          <BuildIcon width={{xs: "22px", md: "24px"}} height={{xs: "22px", md: "24px"}} style={{color: "#FFA31D", margin: "0 auto"}}/>
          <Typography textAlign="center" fontSize={{xs: "18px", md: "20px"}}>페이지 준비중</Typography>
          <Typography width={{md: "414px"}} fontSize={{xs: "14px", md: "16px"}}>현재 아이두는 beta 서비스 기간으로, 해당 페이지는 준비중에 있습니다. 빠른 시일 내에 준비하여 찾아뵙겠습니다. 관련하여 궁금하신 사항은 contact@aidoo.kr로 연락부탁드립니다.</Typography>

          <Button variant="contained" onClick={() => setOpenBetaWarningPopup(false)} sx={{fontSize: {xs: "14px", md: "16px"}, width: "90px", margin: "8px auto 0"}}>확인</Button>
        </Box>
      </Box>
    </>
  )
}
import { Box, Button, Dialog, Typography } from "@mui/material";
import RegisterForm from "./RegisterForm";
import { Dimmed } from "../components/Dimmed";
import CloseIconButton from "../components/CloseIconButton";
import { createBrowserHistory } from "history";
import { useContext, useEffect, useState } from "react";
import Modal from "../components/Modal";
import LoginForm from "../login/LoginForm";
import { UIDataManager } from "../../common/UIDataManager";
import { UIDataContext } from "../../common/UIDataContext";

export default function RegisterModal() {

  const { setOpenLoginModal, setOpenRegisterModal, setOpenTwoButtonPopup } = useContext(UIDataContext);

  return (
    <>
      <Dimmed show={true} zIndex={200}/>
      <Box className="modal-wrap box" width={{xs: "100%", md: "auto"}} height={{xs: "100%", md: "auto"}} top="50%" sx={{zIndex: 201, padding: {xs: "20px 15px", md:  "10px 8px 30px 24px"}}}>
        <Box height="100%">
          <CloseIconButton setShow={setOpenRegisterModal} />
          <Box height="100%" padding={{xs: "20px 5px", md: "30px 70px 10px"}} sx={{ zoom: {md: 0.7} }}>
            <RegisterForm />
          </Box>
        </Box>
      </Box>
    </>
  )
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { createTheme, ThemeProvider } from "@mui/material";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { router } from "./Router";
import { SnackbarProvider } from "notistack";
import Footer from "./ui/components/Footer";
import ScrollTopPageMove from "./common/ScrollTopPageMove";
import { HelmetProvider } from "react-helmet-async";
import { hydrate } from "react-dom";

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

if (process.env.NODE_ENV === "production") { // production에서만 사용할 수 없도록
  console = window.console || {};
  console.log = function no_console() {}; // console log 막기
  console.warn = function no_console() {}; // console warning 막기
  console.error = function () {}; // console error 막기
}

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrateRoot(
    rootElement,
    <HelmetProvider>
      <React.StrictMode>
        <RouterProvider router={router}/>
      </React.StrictMode>
    </HelmetProvider>
  );
} else {
  root.render(
    <HelmetProvider>
      <React.StrictMode>
        <RouterProvider router={router}/>
      </React.StrictMode>
    </HelmetProvider>
  );
}
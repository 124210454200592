import { Box, Button, Stack, Typography } from "@mui/material";
import ServiceCard from "./components/ServiceCard";
import ValueCard from "./components/ValueCard";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from "react-router-dom";
import InstituteInquiryModal from "../modal/InstituteInquriyModal";
import { useContext, useEffect, useState } from "react";
import useAuth from "../../common/useAuth";
import Header from "../components/Header";
import Meta from "../../common/Meta";
import { UIDataContext } from "../../common/UIDataContext";
import { UIDataManager } from "../../common/UIDataManager";
import BetaEventPopup from "./BetaEventPopup";
import { getCookie } from "../../common/CookieUtils";
import { useMediaQuery } from "react-responsive";

export default function MainPage() {

  const [isOpenBetaEventPopup, setOpenBetaEventPopup] = useState(false);

  const [isShowInstituteInquiryModal, setShowInstituteInquiryModal] = useState(false);

  const [flagLogin, setFlagLogin] = useState(false);

  const { setOpenTwoButtonPopup } = useContext(UIDataContext);
  const { requestLoginUser } = useAuth();
  const navigate = useNavigate();

  const mediaXs = useMediaQuery({ minWidth: 0});
  const mediaMd = useMediaQuery({ minWidth: 900});

  useEffect(() => {
    requestLoginUser((data) => {
      setFlagLogin(true);

      if(data.isAgreeBetaEvent === 0) {
        if (!getCookie('betaEventPopup')) {
          setOpenBetaEventPopup(true);
        }
      }
    }, () => {
      setFlagLogin(false);

      if (!getCookie('betaEventPopup')) {
        setOpenBetaEventPopup(true);
      }
    });
  }, []);

  return (
    <>
      <Meta title="aidoo" url="/" />

      <InstituteInquiryModal show={isShowInstituteInquiryModal} setShow={setShowInstituteInquiryModal} initSelectedInstitute={null} />
      { isOpenBetaEventPopup && <BetaEventPopup show={isOpenBetaEventPopup} setShow={setOpenBetaEventPopup}/> }

      <Header flagLogin={flagLogin} setFlagLogin={setFlagLogin} isNavigationGuard={false}/>

      <Box width="100%" component="main" alignItems="flex-end" margin="0 auto">
        <Box component="section" padding={{xs: "50% 20px 50%", md: "100px 20px"}} margin={{xs: "0 auto", md: "90px auto"}} maxWidth="2800px" color={{xs: "#FFFFFF", md: "#000000"}}
            sx={{backgroundColor: {xs: "#020E82", md: "#FFFFFF"}, backgroundImage: {md: "url('/images/main/img_main_background.png')"}, backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: "right bottom"}}>
          <Box maxWidth="1280px" margin="auto">
            <Box component="h1" display="flex" flexWrap="wrap" alignContent="center" alignItems="center" lineHeight="50px">
              <Typography fontSize={{xs: "48px", md: "56px"}} fontFamily="Do Hyeon" fontWeight="500" marginRight={{xs: "0", md: "5px"}}>아이두</Typography>
              {!mediaMd && <br/>}
              <Typography variant="h1" fontFamily="Do Hyeon" fontSize={{xs: "32px", md: "50px"}} fontWeight={{xs: "500", md: "400"}} color={{md: "#454545"}}>60초법정의무교육 계산기</Typography>
            </Box>

            <Typography marginTop="30px" fontSize={{xs: "14px", md: "20px"}} lineHeight={{xs: "18px", md: "32px"}} color={{xs: "#C7C7C7", md: "#1B1B1B"}} sx={{wordBreak: "keep-all"}}>최신 법령과 전문가의 의견을 반영한 AI가 최적의 교육을 추천해 드립니다.<br/>지금 바로 필요한 교육을 진단받아보세요.</Typography>

            <Box display="flex" flexDirection={{xs: "column", md: "row"}} marginTop={{xs: "80px", md: "70px"}} gap="16px">
              {/*<Button variant="outlined" onClick={() => setShowInstituteInquiryModal(true)}*/}
              {/*        sx={{ width: {xs: "220px", md: "273px"}, height: {xs: "48px", md: "70px"}, backgroundColor: "white", fontSize: {xs: "16px", md: "20px"}, fontWeight: "700", gap: "6px"}}>*/}
              {/*  <MailOutlineIcon sx={{width: "24px", height: "24px"}}/>1:1 상담하기*/}
              {/*</Button>*/}
              <Button variant="contained" color={mediaMd ? "primary" : "light"} onClick={() => navigate('/diagnosis')}
                      sx={{ width: {xs: "100%", md: "273px"}, height: {xs: "48px", md: "70px"}, backgroundColor: {xs: "white", md: "#020E82"}, color: {xs: "#020E82", md: "white"}, fontSize: {xs: "16px", md: "20px"}, fontWeight: {md: "700"}, gap: "6px"}}>
                <CheckCircleIcon sx={{width: {xs: "17px", md: "24px"}}}/>지금 바로 진단해보기
              </Button>
            </Box>
          </Box>
        </Box>

        {/* 서비스 */}
        <Box component="section" maxWidth={{xs: "auto", md: "1000px"}} display="flex" flexDirection="column" justifyContent="center" margin={{xs: "50px auto", md: "140px auto"}}>
          <Typography variant="h2" fontSize={{xs: "20px", md: "30px"}} fontWeight="700" textAlign="center">aidoo 서비스</Typography>
          <Box display="flex" flexWrap={{xs: "nowrap", md: "wrap"}} flexDirection={{xs: "column", md: "row"}} gap="40px" justifyContent="center" margin="40px auto 0 auto" >
            <ServiceCard id={1} title="AI 기반 법정의무교육 컨설팅" description="몇 가지 질문에 답하기만 하면, AI가 기업에 필요한 법정의무교육을 정확하게 진단ㆍ추천해드립니다.  쉽고 빠르게 필요한 교육을 확인하세요." />
            <ServiceCard id={2} title="쉽고 정확한 법령 해석" description="aidoo와 함께라면 매년 바뀌는 법령 해석과 법정의무교육 대상 여부를 쉽게 파악할 수 있습니다. 신뢰할 수 있는 새로운 서비스를 경험해보세요. " />
            <ServiceCard id={3} title="최적의 교육 파트너 제안" description="법정의무교육 주관처에서 인증한 신뢰할 수 있는 교육원과 매칭 서비스를 제공합니다. 기업의 정보는 블라인드 처리되어 더 안전하고 쉽게 상담받을 수 있습니다. " />
            <ServiceCard id={4} title="쉽고 빠른 교육 이수관리 시스템" description="aidoo는 체계적인 교육 이수관리 시스템을 제공하여 수료증 보관과 분실 걱정을 덜어줍니다. 이젠 수료증 분실로 겪었던 곤란한 상황과는 이별하세요!" />
          </Box>
        </Box>

        {/* 핵심 가치 */}
        <Box component="section" maxWidth={{xs: "auto", md: "1200px"}} paddingBottom="60px" margin="auto" sx={{backgroundColor: "#F5F5FA"}} padding={{xs: "40px 10px"}}>
          <Typography variant="h2" fontSize={{xs: "20px", md: "30px"}} fontWeight="700" textAlign={{xs: "center", md: "left"}} padding={{xs: "0 0 20px 0", md: "0 40px 40px 40px"}}>aidoo의 핵심 가치</Typography>
          <Box display="flex" flexWrap="wrap"  gap="20px" justifyContent="center">
            <ValueCard id={1} title="신뢰성 (Reliability)" description="최신 법령과 전문가 의견을 반영한 AI 기술을 통해 신뢰할 수 있는 법정의무교육 정보를 신속하게 제공합니다." />
            <ValueCard id={2} title="편리성 (Convenience)" description="정보와 이력을 안전하게 보관하여 인수인계 시간을 절감하고, 전문 지식이 없어도 쉽게 이용할 수 있습니다." />
            <ValueCard id={3} title="효율성 (Efficiency)" description="법령 해석과 교육 검색에 소요되는 불필요한 시간을 줄이고, 쉽고 빠르게 판단을 내릴 수 있도록 돕습니다." />
            <ValueCard id={4} title="사용자 중심 (User-Centric)" description="맞춤형 교육 솔루션으로 사용자 만족도를 높이고, 전문적인 고객 지원으로 신뢰가는 파트너 관계를 구축합니다." />
          </Box>
        </Box>

        <Box component="section" display="flex" flexDirection="column" justifyContent="center" margin="0 auto" padding={{xs: "130px 0 150px", md: "180px 0"}} gap="40px" maxWidth="2800px"
             sx={{backgroundColor: "#01094E", backgroundImage: "url('/images/main/img_main_footer_background.png')", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "bottom"}} >
          <Typography variant="h2" fontSize={{xs: "20px", md: "30px"}} fontWeight="700" lineHeight={{xs: "30px", md: "50px"}} textAlign="center">우리 기업에 반드시 필요한 법정의무교육<br/>아이두와 빠르게 알아보세요.</Typography>
          <Box display="flex" justifyContent="center">
            <Button variant="contained" sx={{width: {xs: "220px", md: "232px"}, height: {xs: "48px", md: "67px"}, fontSize: {xs: "16px", md: "18px"}}} onClick={() => navigate('/diagnosis')}>지금 바로 진단해보기</Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}
import { Box, Typography } from "@mui/material";
import { useContext, useState } from "react";
import PartnerInquiryModal from "../modal/PartnerInquiryModal";
import { UIDataContext } from "../../common/UIDataContext";

export default function Footer () {

  const { setOpenPartnerModal } = useContext(UIDataContext);

  return (
    <Box component="footer" className="footer">
      <Box maxWidth="1440px" margin="0 auto" padding={{xs: "30px 20px", md: "50px 20px"}} color="#FFF">
        <Box component="img" src="/images/light_logo.png" height={{xs: "30px", md: "50px"}}/>
        <Box display="flex" flexDirection="column" gap="5px" borderBottom="1px solid #FFF" marginTop="16px">
          <Typography fontSize={{xs: "12px", md: "16px"}}>주식회사 넷스트림 | 대표이사 상현태 | 사업자 등록 번호 <Box component="span" marginLeft="3px" sx={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => window.open("https://www.ftc.go.kr/bizCommPop.do?wrkr_no=3068701683")}>306-87-01683</Box></Typography>
          <Typography fontSize={{xs: "12px", md: "16px"}}>본사: 강원특별자치도 강릉시 범일로579번길 24, 818호 창조관</Typography>
          <Typography fontSize={{xs: "12px", md: "16px"}}>연구소 : 서울시 금천구 디지털로9길 41, 삼성IT해링턴타워 604호</Typography>
          <Typography fontSize={{xs: "12px", md: "16px"}}>TEL 02-6013-0603 | EMAIL contact@aidoo.kr</Typography>
          <Box height="10px"/>
        </Box>
        <Box display="flex" flexDirection={{xs: "column", md: "row"}} justifyContent="space-between" marginTop={{xs: "10px", md: "30px"}}>
          <Box display="flex" justifyContent={{xs: "space-between", md: "flex-start"}} alignItems="center" >
              <Box component="img" height={{xs: "10px", md: "19px"}} src="/images/footer_netstream_logo.svg"/>
              <Typography fontSize={{xs: "12px", md: "16px"}} color="#FFF" marginLeft="5px">© 2024. Netstream. All Rights Reserved</Typography>
          </Box>
          <Box display="flex" flexWrap="wrap" alignItems="center">
            <Typography fontSize={{xs: "12px", md: "16px"}} onClick={() => setOpenPartnerModal(true)} sx={{cursor: "pointer"}}>파트너 문의</Typography>
            <Box width="1px" height="17px" margin={{xs: "0 12px", md: "0 16px"}} sx={{backgroundColor: "#FFFFFF"}}/>
            <Typography fontSize={{xs: "12px", md: "16px"}} sx={{cursor: "pointer"}}
                        onClick={() => window.open('https://cdn2.netstream.co.kr/aidoo/aidoo-terms-of-use-2024-1.0.0.pdf')}>이용약관</Typography>
            <Box width="1px" height="17px" margin={{xs: "0 12px", md: "0 16px"}} sx={{backgroundColor: "#FFFFFF"}}/>
            <Typography fontSize={{xs: "12px", md: "16px"}} fontWeight="700" sx={{cursor: "pointer"}}
                        onClick={() => window.open('https://cdn2.netstream.co.kr/aidoo/aidoo-privacy-policy-2024-1.0.0.pdf')}>개인정보처리방침</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
import { Box, Typography } from "@mui/material";

/**
 * 진단 결과 목록에 나타낼 컴포넌트
 *
 * @param title
 * @param law
 * @param content
 * @returns {JSX.Element}
 * @constructor
 */
export default function ResultItem({ title, law, content }) {
  return (
    <Box maxWidth="1060px">
      <Typography textAlign="center" fontSize={{xs: "16px", md: "20px"}} fontWeight="700" color="#0E0E0E">{title}</Typography>
      <Typography marginTop="16px" textAlign="center" fontSize={{xs: "12px", md: "16px"}} color="gray" sx={{whiteSpace:"pre-wrap", wordBreak: "keep-all"}}>{law}</Typography>
      <Typography marginTop="20px" textAlign="center" fontSize={{xs: "14px", md: "18px"}} color="#0E0E0E" whiteSpace="pre-wrap"
                  sx={{wordBreak: "keep-all"}}>{content}</Typography>
    </Box>
  )
}
import Modal from "../components/Modal";
import { Box, Button, Typography } from "@mui/material";
import { UIDataManager } from "../../common/UIDataManager";
import { useContext } from "react";
import { UIDataContext } from "../../common/UIDataContext";
import { Dimmed } from "../components/Dimmed";

export default function TwoButtonPopup() {
  const { setOpenTwoButtonPopup } = useContext(UIDataContext);

  const dispose = () => {
    setOpenTwoButtonPopup(false);
  }

  const clickBtn1 = () => {
    if(UIDataManager.twoButtonPopupInfo.btn1Fun) {
      UIDataManager.twoButtonPopupInfo.btn1Fun();
    }
    dispose();
  }

  const clickBtn2 = () => {
    if(UIDataManager.twoButtonPopupInfo.btn2Fun) {
      UIDataManager.twoButtonPopupInfo.btn2Fun();
    }
    dispose();
  }

  return (
    <>
      <Dimmed show={true} zIndex="998"/>
      <Box className="modal-wrap box" minWidth={{xs: "85%", md: "400px"}} maxWidth={{xs: "90%", md: "450px"}} top={{xs: "30%", md: "20%"}} borderRadius="5px" sx={{ padding: "23px 24px 13px 24px", gap: "8px", zIndex: 999 }}>
        <Box>
          <Typography display={UIDataManager.twoButtonPopupInfo?.title ? null : "none"} fontSize={{xs: "16px", md: "20px"}}>{UIDataManager.twoButtonPopupInfo?.title}</Typography>
          <Typography marginTop={UIDataManager.twoButtonPopupInfo?.title ? "15px" : "0"} fontSize={{xs: "14px", md: "16px"}} whiteSpace="pre-wrap" sx={{whiteSpace:"pre-wrap"}}>{UIDataManager.twoButtonPopupInfo?.content}</Typography>
        </Box>

        <Box display="flex" justifyContent="flex-end" gap="8px" marginTop="36px">
          <Button sx={{fontSize: {xs: "14px", md: "16px"}}} variant="outlined" onClick={clickBtn1}>{UIDataManager.twoButtonPopupInfo?.btn1Text}</Button>
          <Button sx={{fontSize: {xs: "14px", md: "16px"}}} variant="contained" onClick={clickBtn2}>{UIDataManager.twoButtonPopupInfo?.btn2Text}</Button>
        </Box>
      </Box>
    </>
  )
}
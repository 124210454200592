import { Box } from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

export default function TopButton() {

  const click = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  return (
    <Box position="fixed" right="2%" bottom="5%" width="32px" height="32px" onClick={click}
         sx={{backgroundColor: "white", color: "#020E82", borderRadius: "4px", border: "3px solid #020E82", cursor: "pointer"}}>
      <KeyboardArrowLeftIcon sx={{ margin: "auto", transform: "rotate(90deg)", fontSize: "32px" }}/>
    </Box>
  )
}
import { Box } from "@mui/material";

export function Dimmed({show, zIndex = 200}) {

  return (
    <Box
      position="absolute"
      display={show?null:"none"}
      zIndex={zIndex}
      top="0" left="0"
      width="100%" height="100%"
      sx={{backgroundColor: "rgba(0, 0, 0, 0.60)"}}
    />
  )
}
import { createBrowserRouter } from "react-router-dom";
import MainPage from "./ui/main/MainPage";
import LoginPage from "./ui/login/LoginPage";
import RegisterPage from "./ui/register/RegisterPage";
import DiagnosisPage from "./ui/diagnosis/DiagnosisPage";
import App from "./App";
import PromptPage from "./ui/admin/prompt/PromptPage";

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: "에러가 발생했습니다.",
    children: [
      {
        path: '/',
        element: <MainPage/>
      },
      {
        path: '/login',
        element: <LoginPage/>
      },
      {
        path: '/register',
        element: <RegisterPage/>
      },
      {
        path: '/diagnosis',
        element: <DiagnosisPage/>
      },
      // {
      //   path: '/admin/prompt',
      //   element: <PromptPage/>
      // }
    ]
  }
]);
import { Box, Typography } from "@mui/material";

export default function ServiceCard({ id, title, description }) {
  return (
    <>
      <Box minWidth={{xs: "85%", md: "390px"}} maxWidth={{xs: "300px", md: "390px"}} margin={{xs: "0 20px", md: "0"}}>
        <Box width={{xs: "100%"}} height={{md: "260px"}} component="img" src={"/images/main/img_main_service" + id + ".png"} alt={title}/>
        <Typography fontWeight="700" fontSize={{xs: "16px", md: "20px"}} marginTop="16px">{title}</Typography>
        <Typography fontSize={{xs: "12px", md: "14px"}} whiteSpace="pre-wrap" marginTop="10px" sx={{wordBreak: {xs: null, md: "keep-all"}}}>{description}</Typography>
      </Box>
    </>
  );
}
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel, IconButton,
  InputAdornment, OutlinedInput,
  TextField,
  Typography
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoginForm from "./LoginForm";
import Header from "../components/Header";
import { createBrowserHistory } from "history";
import { useEffect } from "react";
import useAuth from "../../common/useAuth";
import Meta from "../../common/Meta";

export default function LoginPage() {

  const navigate = useNavigate();
  const history = createBrowserHistory();

  const { requestIsLogin } = useAuth();

  useEffect(() => {
    requestIsLogin(() => {
      navigate('/');
    });
  }, []);

  return (
    <>
      <Meta title="aidoo - 로그인" url="/login" />

      <Header/>
      <Box component="main" className="content-wrap">
        <Box display="flex" justifyContent="center">
          <Box component="img" src="/images/icon.png" height="46px" />
        </Box>
        <Typography marginTop="16px" fontSize="30px" textAlign="center">60초 법정의무교육 계산기</Typography>
        <LoginForm />
      </Box>
    </>
  )
}
import {
  Accordion, AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography
} from "@mui/material";
import { memo, useContext, useEffect, useRef, useState } from "react";
import StepItem from "./components/StepItem";
import ResultItem from "./components/ResultItem";
import RestApi from "../../common/RestApi";
import useAuth from "../../common/useAuth";
import LoadingModal from "./LoadingModal";
import InstituteInquiryModal from "../modal/InstituteInquriyModal";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { createBrowserHistory } from "history";
import Meta from "../../common/Meta";
import Header from "../components/Header";
import { UIDataContext } from "../../common/UIDataContext";
import { UIDataManager } from "../../common/UIDataManager";
import * as pako from 'pako';
import QuestionDetail from "./components/QuestionDetail";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useMediaQuery } from "react-responsive";

let isCancelDiagnosis = false;

export default function DiagnosisPage() {

  const [curStep, setCurStep] = useState(1); // 진단 단계 ( 1 ~ 2 )

  const [questions, setQuestions] = useState(null); // 질문 정보
  const [selectedOptions, setSelectedOptions] = useState([]); // 선택한 옵션 배열

  const [isShowLoading, setShowLoading] = useState(false); // 로딩 팝업
  const [isShowInstituteInquiryModal, setShowInstituteInquiryModal] = useState(false); // 교육관 문의 모달

  const [questionExpands, setQuestionExpands] = useState([]);
  const [isAgree, setAgree] = useState(false); // 동의 여부

  const questionListRef = useRef();

  // ------------------------------------------------------------------------------------------

  const [choiceResult, setChoiceResult] = useState(null);
  const [diagnosisResult, setDiagnosisResult] = useState(null);
  const [educationList, setEducationList] = useState(null);

  const [instituteList, setInstituteList] = useState(null);
  const [selectedInstitute, setSelectedInstitute] = useState(-1);

  const [loginUser, setLoginUser] = useState(null);
  const [flagLogin, setFlagLogin] = useState(false);
  const [isNavigationGuard, setNavigationGuard] = useState(true);

  const [tooltipOpen, setTooltipOpen] = useState(true);

  const { setOpenTwoButtonPopup, setOpenOneButtonPopup, setOpenLoginModal, setOpenSummaryModal, setOpenShareEmailPopup } = useContext(UIDataContext);
  const { requestIsLogin, requestLoginUser, requestPatchLoginUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const mediaMd = useMediaQuery({ minWidth: 900});

  const navigate = useNavigate();
  const history = createBrowserHistory();

  useEffect(() => {
    requestLoginUser((data) => {
      setLoginUser(data);
      setFlagLogin(true);

      if(data.isAgreeBetaEvent) {
        setTooltipOpen(false);
      }
    }, () => {
      setLoginUser(null);
      setFlagLogin(false);
    });
  }, []);

  useEffect( () => {
    if(curStep === 1) {
      setDiagnosisResult(null);
      setEducationList(null);
      setNavigationGuard(true);

      fetchQuestions();
    } else {
      setNavigationGuard(false);

      fetchInstituteList();
    }
  }, [curStep]);

  const preventGoBack = () => {
    if (isNavigationGuard) {
      history.push(null, '', history.location.href);
      UIDataManager.setTwoButtonPopupInfo({
        title: "이전 페이지로 돌아갈 시 진단 중이던 내용이 초기화됩니다.\n이전 페이지로 돌아가시겠습니까?",
        btn1Text: "뒤로가기",
        btn2Text: "계속 진단하기",
        btn1Fun: () => {setNavigationGuard(false); history.go(-2)}
      });
      setOpenTwoButtonPopup(true);
    } else {
      history.go(-2);
    }
  };

  const preventClose = (e) => {
    if (isNavigationGuard) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  useEffect(() => {
    (() => {
      history.push(null, '', history.location.href);

      window.addEventListener('beforeunload', preventClose);
      window.addEventListener('popstate', preventGoBack);
    })();

    return () => {
      window.removeEventListener('beforeunload', preventClose);
      window.removeEventListener('popstate', preventGoBack);
    };
  }, [isNavigationGuard]);

  const fetchInstituteList = async () => {
    try {
      const { data } = await RestApi.request('/inquiry/institute');
      console.log('>> success fetch institute list ', data);

      setInstituteList(data);
    } catch(e) {
      setInstituteList(null);
      console.log('>> error fetch institute list ', e);
    }
  }

  const fetchQuestions = async () => {
    fetch('/data/diagnosis_question_list.json').then(res => res.json()).then(data => {
      console.log('>> fetch question data', data);
      setQuestions(data);
      setSelectedOptions(Array(data.length).fill(''));

      const arr = Array(data.length).fill(false);
      arr[0] = true;
      setQuestionExpands(arr);
    });
  }

  /**
   * 1단계에서 다음 버튼 클릭 시
   */
  const clickNext = async () => {
    const selectedOptionList = getSelectedOptionList();

    isCancelDiagnosis = false;
    setChoiceResult(null);

    console.log(selectedOptionList);
    if(selectedOptionList === null) {
      return;
    }
    else if(!isAgree) {
      UIDataManager.setOneButtonPopupInfo({
        content: "선택되지 않은 항목이 있습니다.\n진단을 계속 진행하기 위해 모든 항목을 선택했는지 확인해주세요.",
        btnText: "확인",
      });
      setOpenOneButtonPopup(true);
    }
    else {
      try {
        setShowLoading(true);

        const body = {
          choice: selectedOptionList,
          memId: loginUser?.memId
        };
        const { data } = await RestApi.request('/diagnosis', 'POST', body);
        const answer = JSON.parse(unzip(data));

        console.log('SUCCESS :: Diagnosis ', answer);

        const education = [];
        answer.map((item, idx) => education.push(item.title));

        if(isCancelDiagnosis) {
          return;
        }

        setChoiceResult(selectedOptionList);
        setDiagnosisResult(answer);
        setEducationList(education);

        window.scrollTo(0, 0);
        setCurStep(2);
      } catch (e) {
        console.log('ERROR :: Diagnosis error ', e);
        if(e.error === "Conflict") {
          enqueueSnackbar("서버 오류가 발생했습니다. 나중에 다시 시도해주세요.", {variant: "error"});
          navigate('/');
        }
      }
    }
    setShowLoading(false);
  }

  const unzip = (text) => {
    const gezipedData = atob(text)
    const gzipedDataArray = Uint8Array.from(gezipedData, c => c.charCodeAt(0))

    const ungzipedData = pako.ungzip(gzipedDataArray);

    return new TextDecoder().decode(ungzipedData);
  }

  const getSelectedOptionList = () => {
    const result = {};
    for (let i = 0; i < questions.length; i++) {
      const question = questions[i];
      const option = selectedOptions[question.id];

      if(!isQuestionShow(question.id)) continue;

      console.log(i, ' ', question);
      if(question.type === 'single') {
        if(option === ''){
          spreadAccordion(i);

          const elements = questionListRef.current;
          const target = elements.children[i];

          window.scrollTo({top: target.getBoundingClientRect().top + window.scrollY , behavior: "smooth"});
          UIDataManager.setOneButtonPopupInfo({
            content: "선택되지 않은 항목이 있습니다.\n진단을 계속 진행하기 위해 모든 항목을 선택했는지 확인해주세요.",
            btnText: "확인",
          });
          setOpenOneButtonPopup(true);

          return null;
        }

        result[question.key] = question.options[option].title;
      }
      else if(question.type === 'multiple') {
        if(option === '') {
          continue;
        }
        result[question.key] = '';

        const split = (option + '').split('');
        for(let j = 0; j < split.length; j++) {
          result[question.key] += question.options[split[j].charCodeAt(0) - 65].title + '\n';
        }
      }
    }

    console.log('>> user selected option ', result);
    return result;
  }

  const isQuestionShow = (id) => {
    const target = questions.filter((item) => item.id === id)[0];
    const disable = target?.disable;

    if (disable !== undefined) {
      if (disable === 1 || selectedOptions[disable.targetId] !== disable.targetOption) {
        return false;
      }
    }
    return true;
  }

  const spreadAccordion = (idx) => {
    const arr = [...questionExpands];
    arr[idx] = true;
    setQuestionExpands(arr);
  }

  const clickPrevious = () => {
    UIDataManager.setTwoButtonPopupInfo({
          content: "이전 페이지로 돌아갈 시 진단 중이던 내용이 초기화됩니다.\n이전 페이지로 돌아가시겠습니까?",
          btn1Text: "뒤로가기",
          btn2Text: "계속 진단하기",
          btn1Fun: () => {setNavigationGuard(false); navigate('/'); }
    });
    setOpenTwoButtonPopup(true);
  }

  const clickSummary = () => {
    requestLoginUser((data) => {
      setLoginUser(data);

      if(data?.isAgreeBetaEvent === 0) {
        UIDataManager.setTwoButtonPopupInfo({
          title: "이벤트 참여 확인",
          content: "아이두 베타 오픈 기념 무료 상담 이벤트에 참여합니다.",
          btn1Text: "아니오",
          btn2Text: "네",
          btn1Fun: () => {
            openSummary();
          },
          btn2Fun: async () => {
            requestPatchLoginUser({ isAgreeBetaEvent: '1' }, (data) => { setLoginUser(data) });
            openSummary();
          }
        });
        setOpenTwoButtonPopup(true);
      }
      else {
        openSummary();
      }
    }, () => {
      openSummary();
    });
  }

  const openSummary = () => {
    if(mediaMd) {
      requestIsLogin(() => {
        UIDataManager.setSummaryModalInfo({
          diagnosisEdu: educationList,
          choice: choiceResult,
        });
        setOpenSummaryModal(true);
      }, () => {
        UIDataManager.setLoginModalInfo({
          succFun: () => {
            requestLoginUser((data) => {
              UIDataManager.setSummaryModalInfo({
                diagnosisEdu: educationList,
                choice: choiceResult,
              });

              setOpenSummaryModal(true);
              setFlagLogin(true);
              setLoginUser(data);
            }, () => {
              setFlagLogin(false);
              setLoginUser(null);
            });
          }
        });
        setOpenLoginModal(true);
      });
    }
    else {
      UIDataManager.setShareEmailPopupInfo({
        url: "/diagnosis/summary/share",
        context: {
          education: educationList,
          companyName: loginUser?.companyName || '',
          employeeCount: choiceResult?.employee || '',
          business: choiceResult?.business || '',
          companyNumber: loginUser?.companyNumber || '',
          email: loginUser?.email || '-',
          phone: loginUser?.phone || '-',
        }
      });
      setOpenShareEmailPopup(true);
    }
  }

  const handlePreviousClick = () => {
    setCurStep(1);
    setAgree(false);
    window.scrollTo(0, 0);
  }

  const handleAgreeChange = (e) => {
    setAgree(e.target.checked);
  }

  const cancelLoading = () => {
    console.log('>> loading cancel !!');
    isCancelDiagnosis = true;
    setShowLoading(false);
  }

  const InstituteBlock = ({id, name, description, icon}) => {
    const clickEnter = () => {
      setSelectedInstitute(id);
      setShowInstituteInquiryModal(true);
    }

    const getHiddenName = () => {
      const len = name.length;
      const hide = name.substring(0, 1) + "*".repeat(len - 2) + name.substring(len - 1, len);
      return hide;
    }

    return (
      <Box display="flex" width="550px" flexDirection="row" borderRadius="10px" padding="25px 20px" sx={{ backgroundColor: "white" }}>
        <Box><Box width="108px" height="108px" component="img" src={`/images/institute/${icon}.png`}/></Box>
        <Box display="flex" flexDirection="column" gap="13px" marginLeft="16px">
          <Typography fontSize="20px" fontWeight="700">{flagLogin ? name : getHiddenName()}</Typography>
          <Typography width="285px" fontSize="16px" sx={{whiteSpace: "pre-line"}}>{description}</Typography>
        </Box>
        <Box margin="auto" marginLeft="28px">
          <Button variant="contained" sx={{ padding: "10px 20px" }} onClick={clickEnter}>1:1 상담</Button>
        </Box>
      </Box>
    )
  }

  return (
    <>
      <Meta title="AI 진단하기" url="/diagnosis" />

      <Header flagLogin={flagLogin} setFlagLogin={setFlagLogin} isNavigationGuard={isNavigationGuard}/>

      <Box component="main" className="content-wrap" padding="40px 20px">
        <Box width="100%" display="flex" flexDirection="row" justifyContent="flex-start">
          <StepItem step={1} title="기업 정보 입력" description="귀사의 정보를 입력해주세요." curStep={curStep}/>
          <StepItem step={2} title="진단 결과 보기" description="AI가 진단한 결과를 확인해보세요." curStep={curStep}/>
        </Box>

        {/* STEP 1 */}
        <Box display={curStep===1?"flex":"none"} flexDirection="column" gap="40px" >
          <Typography fontSize={{xs: "20px", md: "30px"}} textAlign="center" marginTop="40px">
            아래 질문에 답해주시면<br/>필요한 교육을 <Box component="span" fontWeight="700">빠르게 진단해드릴게요!</Box>
          </Typography>
          {/* 질문 리스트 */}
          <Box ref={questionListRef} display="flex" flexDirection="column" margin="0 auto" gap="20px">
            {
              questions?.map((item, idx) => {
                const scrollToPosition = (targetPosition, duration) => {
                  const startPosition = window.pageYOffset;
                  const distance = targetPosition - startPosition;
                  let startTime = null;

                  const easeInOutQuad = (time, from, distance, duration) => {
                    time /= duration / 2;
                    if (time < 1) return (distance / 2) * time * time + from;
                    time--;
                    return (-distance / 2) * (time * (time - 2) - 1) + from;
                  };

                  const animation = (currentTime) => {
                    if (startTime === null) startTime = currentTime;
                    const timeElapsed = currentTime - startTime;
                    const run = easeInOutQuad(timeElapsed, startPosition, distance, duration);
                    window.scrollTo(0, run);
                    if (timeElapsed < duration) requestAnimationFrame(animation);
                  };

                  requestAnimationFrame(animation);
                };

                const changeSelectedOptionList = (option) => {
                  const options = selectedOptions.map((prev, i) => (i === item.id ? option : prev));
                  setSelectedOptions(options);

                  for(let i = idx + 1; i < questions.length; i++) {
                    const item = questions[i];
                    const disable = item?.disable;

                    if (disable !== undefined) {
                      if (disable === 1 || options[disable.targetId] !== disable.targetOption) {
                        continue;
                      }
                    }
                    spreadAccordion(i);
                    break;
                  }
                  if(item.type === "single") {
                    const elements = questionListRef.current;
                    const target = elements.children[idx];

                    scrollToPosition(target.getBoundingClientRect().bottom + window.scrollY + 20, item.options.length / (idx === 0 ? 3.5 : 4) * 1000);
                    // window.scrollTo({top: target.getBoundingClientRect().bottom + window.scrollY + 20, behavior: "smooth"});
                  }
                }

                const changeExpandAccordion = () => {
                  const arr = [...questionExpands];
                  arr[idx] = !arr[idx];
                  setQuestionExpands(arr);
                }

                return (
                  <Box key={item.id} display={isQuestionShow(item.id)?null:"none"}>
                    <Accordion disableGutters expanded={questionExpands[idx]} onChange={changeExpandAccordion} sx={{width: {xs: "99%", md: "1180px"}, border: "2px solid #8F8F8F", borderRadius: "10px", boxShadow: "none"}}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon sx={{width: {xs: "24px", md: "30px"}}} />}>
                        <Box display="flex" gap="8px">
                          <CheckCircleOutlineIcon sx={{width: "30px"}}/>
                          <Box display="flex" flexDirection="column" gap="10px">
                            <Typography fontSize={{xs: "16px", md: "20px"}} fontWeight="700">{item.question}</Typography>
                            <Typography display={questionExpands[idx] ? null : "none"} fontSize={{xs: "12px", md: "14px"}} color="#D70909"><Box component="span" fontWeight="700" marginRight="5px">TIP</Box>{item.tip}</Typography>
                          </Box>
                        </Box>
                      </AccordionSummary>

                      <QuestionDetail id={idx} type={item.type} options={item.options} isOpen={() => questionExpands[idx]} changeSelectedOptionList={changeSelectedOptionList}></QuestionDetail>
                    </Accordion>
                  </Box>
                )
              })
            }
          </Box>
          {/* 약관 체크박스 */}
          <Box display="flex" justifyContent="flex-end" marginRight="20px">
            <FormControlLabel control={<Checkbox name="gilad" checked={isAgree} onClick={handleAgreeChange}/>} labelPlacement="start"
                              label={<Typography textAlign="right" fontSize={{xs: "12px", md: "20px"}} sx={{wordBreak: "keep-all"}}>기입한 정보를 기반으로 도출되는 진단 결과는 법적인 효력이 없음을 인지하고 동의합니다.</Typography>} />
          </Box>
          {/* 하단 버튼 그룹 */}
          <Box display="flex" gap="16px" marginBottom="40px" marginRight={{xs: "15px", md: "50px"}} flexDirection="row" justifyContent="flex-end">
            <Button variant="outlined" onClick={clickPrevious} sx={{fontSize: "16px", padding: "15px 30px"}}>이전</Button>
            <Button variant="contained" onClick={clickNext} sx={{fontSize: "16px", fontWeight: "700", width: "166px"}}>진단하러 가기</Button>
          </Box>
        </Box>

        {/* ############################################################################################################################################################################### */}

        {/* STEP 2 */}
        <Box display={curStep === 2?"flex":"none"} flexDirection="column" gap="40px" margin="30px auto 0 auto">
          <Typography fontSize={{xs: "18px", md: "30px"}} textAlign="center">지금 <Box component="span" fontWeight="700">리포트로 요약</Box>하시면<br/>아래 결과를 기반으로 최종 리포트를 생성합니다.</Typography>

          {/* 진단 결과 리스트 */}
          <Box display="flex" flexDirection="column" gap="40px" height="863px" borderRadius="10px" border="2px solid #8F8F8F" padding={{xs: "30px", md: "30px 100px"}} sx={{overflowY: "auto"}}>
            {
              diagnosisResult?.map((item, idx) => (
                <ResultItem key={idx} title={item.title} content={item.content} law={item.law} />
              ))
            }
          </Box>
          <Typography fontSize={{xs: "10px", md: "14px"}}>본 진단 결과는 법령 및 전문기관의 의견을 기반으로 도출된 데이터이나, 실제 기업에서 하는 업무와 사업장의 상황에 따라 받으셔야 하는 교육은 진단된 결과와 차이가 발생할 수 있습니다. 해당 정보는 참고 및 내부용으로 활용하시길 바라며, 더 자세한 내용이 궁금하시다면 아래에 마련된 무료 상담을 이용하시는 것을 추천드립니다.</Typography>
          <Box display="flex" justifyContent="flex-end">

            <Box display="flex" flexDirection="column" justifyContent="flex-end" alignContent="flex-end">

              <Box display={tooltipOpen ? "flex" : "none"} marginBottom="7px">
                <Box width="185px">
                  <Typography fontSize="14px" color="white" padding="10px 16px" borderRadius="4px" textAlign="center" sx={{backgroundColor: "rgba(41, 41, 41, 0.80)"}}>커피&무료상담 진행중!</Typography>
                  <Box margin="0 auto" width="1px" sx={{
                    borderTop: "10px solid rgba(41, 41, 41, 0.80)",
                    borderLeft: "10px solid transparent",
                    borderRight: "10px solid transparent",
                    borderBottom: "0px solid transparent"
                  }}>
                  </Box>
                </Box>
                <Box width={{xs: "50px", md: "80px"}}></Box>
              </Box>

              <Box display="flex" justifyContent="flex-end">
                <Button variant="contained" onClick={clickSummary}
                        sx={{ textAlign: "left", width: {xs: "160px", md: "218px"}, height: {xs: "60px", md: "84px"}, display:"flex", flexDirection: "column"}}>
                  <Typography fontSize={{xs: "14px", md: "16px"}}>쉽게 보고 싶으세요?</Typography>
                  <Typography fontSize={{xs: "14px", md: "16px"}} fontWeight="700">{mediaMd ? "요약 리포트 보기" : "요약 리포트 전송"}</Typography>
                </Button>
              </Box>
            </Box>
          </Box>

          <Box display="none" flexDirection="column" padding="30px 40px" marginTop="30px">
            {/* sx={{ backgroundColor: "rgba(2, 14, 130, 0.10)"}}> */}
            {/*<Typography textAlign="center" fontWeight="700" fontSize="30px">아이두와 함께하는 추천 교육원</Typography>*/}
            {/*<Typography marginTop="10px" textAlign="center" fontSize="20px" color="#0E0E0E">지금 무료로 상담 받으세요!</Typography>*/}
            {/*/!* 교육기관 리스트 *!/*/}
            {/*<Box display="flex" flexDirection="row" margin="40px auto" flexWrap="wrap" gap="20px" justifyContent="center">*/}
            {/*  {*/}
            {/*    instituteList?.map((item, idx) => (*/}
            {/*      <InstituteBlock key={idx} name={item.name} description={item.pageDescription} id={item.instituteId} icon={item.icon}/>*/}
            {/*    ))*/}
            {/*  }*/}
            {/*</Box>*/}
            {/*<Box display="flex" justifyContent="flex-end" marginTop="20px" marginBottom="40px" >*/}
            {/*  <Button variant="outlined" onClick={handlePreviousClick} sx={{backgroundColor: "white", padding: "15px 30px", fontSize: "16px"}}>다시 진단하기</Button>*/}
            {/*</Box>*/}
          </Box>
        </Box>
      </Box>

      {/* modal & popup */}
      <LoadingModal show={isShowLoading} setShow={setShowLoading} eventCancel={cancelLoading}/>
      <InstituteInquiryModal show={isShowInstituteInquiryModal} setShow={setShowInstituteInquiryModal} initSelectedInstitute={selectedInstitute} diagnosis={diagnosisResult}/>
    </>
  )
}

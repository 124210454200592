import { Box, Dialog } from "@mui/material";
import LoginForm from "./LoginForm";
import CloseIconButton from "../components/CloseIconButton";
import Modal from "../components/Modal";
import { Dimmed } from "../components/Dimmed";
import { useContext } from "react";
import { UIDataManager } from "../../common/UIDataManager";
import { UIDataContext } from "../../common/UIDataContext";

export default function LoginModal() {

  const { setOpenLoginModal, setOpenRegisterModal } = useContext(UIDataContext);

  return (
    <>
      <Dimmed show={true} zIndex={200}/>
      <Box className="modal-wrap box" width={{xs: "100%", md: "550px"}} height={{xs: "100%", md: "auto"}} top="50%" sx={{zIndex: 201, padding: {xs: "20px 15px", md:  "30px 40px 40px 40px"}}}>
        <Box>
          <CloseIconButton setShow={setOpenLoginModal} />
          <Box padding={{xs: "20px 15px",md: "0 100px"}} sx={{ zoom: {md: 0.8} }}>
            <LoginForm/>
          </Box>
        </Box>
      </Box>
    </>
  )
}
import { Box, Button, IconButton, InputAdornment, OutlinedInput, TextField, Typography } from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { useContext, useState } from "react";
import useAuth from "../../common/useAuth";
import BetaWarningPopup from "../popup/BetaWarningPopup";
import OneButtonPopup from "../popup/OneButtonPopup";
import { UIDataManager } from "../../common/UIDataManager";
import { UIDataContext } from "../../common/UIDataContext";
import { useNavigate } from "react-router-dom";

export default function LoginForm() {

  const { login } = useAuth();

  const [userId, setUserId] = useState(''); // 아이디
  const [password, setPassword] = useState(''); // 비밀번호

  const [showPassword, setShowPassword] = useState(false); // 비밀번호 숨기기 or 나타내기

  const { setOpenBetaWarningPopup, isOpenLoginModal, setOpenLoginModal, setOpenRegisterModal, setOpenOneButtonPopup } = useContext(UIDataContext);
  const navigate = useNavigate();

  const keyPressInput = async (e) => {
    // enter key press
    if(e.key === "Enter") {
      if (userId !== "" && password !== "") {
        await clickLogin();
      }
    }
  }

  const clickRegister = () => {
    if(isOpenLoginModal) {
      setOpenLoginModal(false);
      setOpenRegisterModal(true);
    }
    else {
      navigate('/register');
    }
  }

  const clickLogin = async () => {
    if (userId === "") {
      UIDataManager.setOneButtonPopupInfo({
        title: "로그인 정보 불일치",
        content: "사업자등록번호를 입력해주세요."
      });
      setOpenOneButtonPopup(true);
    }
    else if (password === "") {
      UIDataManager.setOneButtonPopupInfo({
        title: "로그인 정보 불일치",
        content: "비밀번호를 입력해주세요."
      });
      setOpenOneButtonPopup(true);
    }
    else {
      try {
        await login(userId, password);
        if(isOpenLoginModal) {
          setOpenLoginModal(false);
          if(UIDataManager.loginModalInfo?.succFun) {
            UIDataManager.loginModalInfo?.succFun();
          }
        }
        else {
          navigate('/');
        }
      } catch (e) {
        if(e.error === "Bad Request") {
          UIDataManager.setOneButtonPopupInfo({
            title: "로그인 정보 불일치",
            content: "비밀번호가 일치하지 않습니다."
          });
        } else if(e.error === "Not Found") {
          UIDataManager.setOneButtonPopupInfo({
            title: "로그인 정보 불일치",
            content: "아이두에 가입되지 않은 사업자등록번호입니다."
          });
        }
        setOpenOneButtonPopup(true);
      }
    }
  }

  return (
    <>
      <Typography marginTop="20px" fontSize={{xs: "24px", md: "40px"}} fontWeight="700" color="#0E0E0E" textAlign="center">로그인</Typography>

      <Box component="form" noValidate autoComplete="off" display="flex" flexDirection="column" justifyContent="center" gap="10px" width={{xs: "100%", md: "480px"}} marginTop="80px">
        <TextField
          type="number" variant="outlined"
          placeholder="사업자등록번호를 -없이 입력해주세요." fullWidth
          onChange={(e) => setUserId(e.target.value)} value={userId}
          onKeyPress={keyPressInput}
          sx={{'.MuiInputBase-input': { fontSize: {xs: "12px", md: "16px" }}, marginBottom: "16px"}}
        />
        <OutlinedInput
          placeholder="대소문자와 특수문자를 포함한 8~16자리 비밀번호"
          value={password} type={showPassword ? 'text' : 'password'} fullWidth
          onChange={(e) => setPassword(e.target.value)}
          onKeyPress={keyPressInput}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword((show) => !show)}
                onMouseDown={(e) => e.preventDefault()}
                edge="end"
              >
                {showPassword ? <VisibilityOff sx={{width: {xs: "20px"}}}/> : <Visibility  sx={{width: {xs: "20px"}}}/>}
              </IconButton>
            </InputAdornment>
          }
          sx={{'.MuiInputBase-input': { fontSize: {xs: "12px", md: "16px" }}, marginBottom: "16px"}}
        />

        <Box marginTop="20px" marginBottom="20px">
          <Button fullWidth variant="contained" sx={{height: {xs: "40px", md: "56px"}, fontSize: {xs: "14px", md: "20px"}}} onClick={clickLogin}>로그인</Button>
          <Box display="flex" flexDirection="row" justifyContent="space-between" marginTop="10px">

            {/* TODO 추후 마이페이지 개발 후 연결 필요*/}
            <Typography onClick={() => setOpenBetaWarningPopup(true)}  fontSize={{xs: "14px", md: "16px"}} sx={{cursor: "pointer"}}>비밀번호 찾기</Typography>
            <Typography onClick={clickRegister} fontSize={{xs: "14px", md: "16px"}} sx={{cursor: "pointer"}}>회원가입</Typography>
          </Box>
        </Box>
      </Box>
    </>
  )
}
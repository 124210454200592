import {
  Box,
  Button,
  Typography
} from "@mui/material";
import { useContext } from "react";
import { UIDataContext } from "../../common/UIDataContext";
import { UIDataManager } from "../../common/UIDataManager";

export default function OneButtonPopup() {
  const { setOpenOneButtonPopup } = useContext(UIDataContext);

  const dispose = () => {
    setOpenOneButtonPopup(false);
  }

  return (
    <>
      <Box position="absolute" zIndex="998" left="0" width="100%" height="100%" sx={{backgroundColor: "rgba(0, 0, 0, 0.60)"}}/>
      <Box className="modal-wrap box" minWidth={{xs: "85%", md: "400px"}} maxWidth={{xs: "90%", md: "450px"}} top={{xs: "30%", md: "20%"}} borderRadius="5px" sx={{ padding: "23px 24px 13px 24px", gap: "8px", zIndex: 999 }}>
        <Typography display={UIDataManager.oneButtonPopupInfo?.title? null : "none"} fontSize="20px">{UIDataManager.oneButtonPopupInfo?.title}</Typography>

        <Typography marginTop={UIDataManager.oneButtonPopupInfo?.title?"7px":"0"} fontSize="16px" whiteSpace="pre-wrap" sx={{whiteSpace:"pre-wrap", wordBreak: "keep-all"}}>
          {UIDataManager.oneButtonPopupInfo?.content}
        </Typography>

        <Box display="flex" justifyContent="flex-end" gap="8px" marginTop={UIDataManager.oneButtonPopupInfo?.title?"10px":"24px"}>
          <Button sx={{fontSize: "16px", minWidth: "95px"}} variant="contained" onClick={dispose}>확인</Button>
        </Box>

      </Box>
    </>
  )
}
import {
  Box,
  Button, Link,
  Typography
} from "@mui/material";
import RegisterForm from "./RegisterForm";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import useAuth from "../../common/useAuth";
import { useContext, useEffect, useState } from "react";
import Header from "../components/Header";
import { createBrowserHistory } from "history";
import Meta from "../../common/Meta";
import { UIDataContext } from "../../common/UIDataContext";
import { UIDataManager } from "../../common/UIDataManager";

export default function RegisterPage() {
  const [isNavigationGuard, setNavigationGuard] = useState(true);
  const { setOpenNavigationGuardPopup } = useContext(UIDataContext);

  const { requestIsLogin } = useAuth();

  const history = createBrowserHistory();
  const navigate = useNavigate();

  useEffect(() => {
    requestIsLogin(() => {
      navigate('/');
    });
  }, []);

  const preventGoBack = () => {
    if (isNavigationGuard) {
      history.push(null, '', history.location.href);
      UIDataManager.setTwoButtonPopupInfo({
        content: "이 페이지를 벗어나면 입력하셨던 내용이 모두 사라질 수 있습니다.\n페이지를 이동하시겠습니까?",
        btn1Text: "이동하기",
        btn2Text: "계속 가입하기",
        btn1Fun: () => {setNavigationGuard(false); history.go(-2);}
      });
      setOpenNavigationGuardPopup(true);
    } else {
      history.go(-2);
    }
  };

  const preventClose = (e) => {
    if (isNavigationGuard) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  useEffect(() => {
    (() => {
      history.push(null, '', history.location.href);

      window.addEventListener('beforeunload', preventClose);
      window.addEventListener('popstate', preventGoBack);
    })();

    return () => {
      window.removeEventListener('beforeunload', preventClose);
      window.removeEventListener('popstate', preventGoBack);
    };
  }, [isNavigationGuard]);

  return (
    <>
      <Meta title="aidoo - 회원가입" url="/register" />
      <Header isNavigationGuard={isNavigationGuard} />

      {/* main */}
      <Box className="content-wrap">
        <Box display="flex" flexDirection="column">
          <Box display="flex" justifyContent="center" marginTop="30px"><Box component="img" src="/images/icon.png" height="46px"/></Box>
          <Typography textAlign="center" fontSize="30px" marginTop="16px">60초 법정의무교육 계산기</Typography>
          <Box marginBottom="90px">
            <RegisterForm />
          </Box>
        </Box>
      </Box>
    </>
  )
}
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useRef, useState } from "react";
import { click } from "@testing-library/user-event/dist/click";

export default function QuestionDetail({id, type, options, changeSelectedOptionList}) {
  const [selectedOption, setSelectedOption] = useState('');

  const clickOption = (idx) => {
    if (type === "single") {
      setSelectedOption(idx);
      changeSelectedOptionList(idx);
    }
    else if (type === "multiple") {
      const ch = String.fromCharCode(idx + 65);
      const options = (selectedOption === '' ? ch : selectedOption.includes(ch) ? (selectedOption.length === 1 ? '' : selectedOption.replace(ch, '')) : selectedOption + ch);

      setSelectedOption(options);
      changeSelectedOptionList(options);
    }
  }

  const isSelected = (idx) => {
    if (type === "single") {
      return idx === selectedOption;
    }
    else if (type === "multiple") {
      const ch = String.fromCharCode(idx + 65);
      return selectedOption.includes(ch);
    }
  }

  return (
    <AccordionDetails>
      <Box display="flex" justifyContent="center" flexDirection="row" flexWrap="wrap" gap="16px">
        {
          options.map((item, idx) => {
            return (
              item.content
                ?
                  <Accordion key={idx} elevation={0} defaultExpanded={true} disableGutters
                             sx={{
                               width: {xs: "260px", md: "240px"},
                               alignSelf: "start",
                               borderRadius: "10px",
                               border: "1px solid " + (isSelected(idx) ? "#020E82" : "#8F8F8F"),
                               cursor: "pointer",
                               backgroundColor: isSelected(idx) ? "#CCCFE6" : "white",
                               '&::before': {border: "none", height: "0px"}
                  }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={{width: {xs: "24px", md: "30px"}}} />} sx={{border: "none"}}>
                      <Typography width="100%" textAlign="center" fontSize={{xs: "18px", md: "20px"}} fontWeight="700" color={isSelected(idx) ? "#020E82" : "#0E0E0E"}>{item.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ height: "100px", border: "none"}} onClick={() => clickOption(idx)}>
                      <Typography textAlign="center" fontSize="16px" whiteSpace="pre-wrap" color={isSelected(idx) ? "#020E82" : "#0E0E0E"}>{item.content}</Typography>
                    </AccordionDetails>
                  </Accordion>
                :
                  <Box key={idx} display="flex" width={{xs: "260px", md: "240px"}} minHeight={{xs: "45px", md: "55px"}} padding="10px" borderRadius="10px" onClick={() => clickOption(idx)}
                       sx={{border: "1px solid " + (isSelected(idx) ? "#020E82" : "#8F8F8F"), cursor: "pointer", backgroundColor: isSelected(idx) ? "#CCCFE6" : "white"}}>
                    <Typography whiteSpace="pre-wrap" margin="auto" textAlign="center" fontSize="16px" color={isSelected(idx) ? "#020E82" : "#0E0E0E"} sx={{wordBreak: "keep-all"}}>
                      {item.title}
                    </Typography>
                  </Box>
            )
          })
        }
      </Box>
    </AccordionDetails>
  )
}
import { Dimmed } from "../components/Dimmed";
import { Box, Button, Checkbox, FormControlLabel, OutlinedInput, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../common/useAuth";
import RestApi from "../../common/RestApi";
import { useSnackbar } from "notistack";
import CloseIconButton from "../components/CloseIconButton";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

export default function InstituteInquiryModal({show, setShow, initSelectedInstitute, diagnosis = null}) {

  const [companyNumber, setCompanyNumber] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [companyPhone, setCompanyPhone] = useState('');

  const [onlyShowSelectedInstitute, setOnlyShowSelectedInstitute] = useState(false);
  const [selectedInstitute, setSelectedInstitute] = useState(1);
  const [instituteList, setInstituteList] = useState(null);

  const [checkAgree, setCheckAgree] = useState(false);
  const [checkboxItems, setCheckboxItems] = useState([]);

  const [errCompanyNumber, setErrCompanyNumber] = useState('');
  const [errCompanyEmail, setErrCompanyEmail] = useState('');
  const [errCompanyPhone, setErrCompanyPhone] = useState('');

  const [isCheckCompanyNumber, setCheckCompanyNumber] = useState(false);

  const [flagLogin, setFlagLogin] = useState(false);

  const { requestLoginUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const ref = useRef();
  const navigate = useNavigate();

  // 체크 박스 내용
  const checkboxContents = [
    "법정의무교육에 대해 잘 모릅니다. 자세히 알려주세요.",
    "올해 이미 교육 받은 경험이 있습니다.",
    "법정의무교육의 빠른 이수를 원합니다.",
    "불법 스팸 또는 협박 전화 등 신뢰하기 어려운 연락을 받았습니다.",
    "급여제공지침교육 및 부패방지교육, 자금세탁방지교육이 필요합니다."
  ];

  useEffect(() => {
    fetchInstituteList();
  }, []);

  useEffect(() => {
    if(show) {
      ref.current?.scrollIntoView({ behavior: 'smooth' });

      setSelectedInstitute(initSelectedInstitute === null ? 1 : initSelectedInstitute);
      setOnlyShowSelectedInstitute(initSelectedInstitute !== null);

      requestLoginUser((data) => {
        setFlagLogin(true);
        setCompanyNumber(data?.companyNumber || '');
        setCompanyEmail(data?.companyEmail || '');
        setCompanyPhone(data?.companyPhone || '');
      }, () => {
        setFlagLogin(false);
        setCompanyNumber('');
        setCompanyEmail('');
        setCompanyPhone('');
      });
    }
  }, [show]);
  const fetchInstituteList = async () => {
    try {
      const { data } = await RestApi.request('/inquiry/institute');
      console.log('>> success fetch institute list ', data);

      setInstituteList(data);
    } catch(e) {
      setInstituteList(null);
      console.log('>> error fetch institute list ', e);
    }
  }

  const clickEnter = async () => {
    if(companyNumber === "") {
      setErrCompanyNumber("사업자등록번호를 입력해주세요.");
    }
    else if(companyEmail === "") {
      setErrCompanyEmail("회사 이메일을 입력해주세요.");
    }
    else if(companyPhone === "") {
      setErrCompanyPhone("회사 연락처를 입력해주세요.");
    }
    else if(errCompanyPhone !== "" || errCompanyNumber !== "" || errCompanyEmail !== "") {
      // error 가 있을 경우
    }
    else if(!isCheckCompanyNumber && !flagLogin) {
      setErrCompanyNumber("사업자등록번호를 확인해주세요.");
    }
    else if(checkboxItems.length === 0) {
      enqueueSnackbar("교육원에 전달할 내용을 선택해주세요.", {variant: "error"})
    }
    else if(!checkAgree) {
      enqueueSnackbar("약관에 동의해주세요", {variant: "error"})
    }
    else {
      try {
        const content = checkboxContents.filter((item, idx) =>  checkboxItems.includes(idx)).join('\n');

        const url = "/inquiry/institute";
        const body = {
          instituteId: selectedInstitute,
          companyNumber: companyNumber,
          companyEmail: companyEmail,
          companyPhone: companyPhone,
          diagnosis: diagnosis && JSON.stringify(diagnosis),
          content: content,
        }

        const { data } = await RestApi.request(url, "POST", body);
        enqueueSnackbar("교육원으로 1:1 상담 요청이 완료되었습니다.", {variant: "success"});
      } catch (e) {
        console.log("ERROR :: create institute inquiry ", e);
      }
      setShow(false);
    }
  }

  const checkCheckbox = (e) => {
    const { id, checked } = e.target;
    if(checked) {
      setCheckboxItems(prve => [...prve, +id]);
    } else {
      setCheckboxItems(checkboxItems.filter((item) => item !== +id));
    }
  }

  const clickValidateCompanyNumber = async () => {
    if(companyNumber === "") {
      setErrCompanyNumber("사업자등록번호를 입력해주세요.");
    }
    else if(errCompanyNumber !== "") {
    }
    else if(companyNumber.length !== 10) {
      setErrCompanyNumber('사업자등록번호 10자리를 입력해주세요.');
    }
    else {
      try {
        await RestApi.request('/auth/validate/companyNumber?companyNumber='+companyNumber+"&same=0", 'GET');
        enqueueSnackbar("사업자등록번호가 확인되었습니다.", {variant: "success"});
        setCheckCompanyNumber(true);
      } catch (e) {
        if(e.error === "Bad Request") {
          setErrCompanyNumber("아이두에 가입된 사업자등록번호입니다. ");
        } else if(e.error === "Unauthorized") {
          setErrCompanyNumber("존재하지 않는 사업자등록번호입니다.");
        }
      }
    }
  }

  const changeCompanyNumber = (e) => {
    const value = e.target.value.substring(0, 10);
    setCompanyNumber(value);
    setCheckCompanyNumber(false);
    validateCompanyNumber(value);
  }
  const changeCompanyEmail = (e) => {
    setCompanyEmail(e.target.value);
    validateCompanyEmail(e.target.value);
  }
  const changeCompanyPhone = (e) => {
    setCompanyPhone(e.target.value);
    validateCompanyPhone(e.target.value);
  }

  const validateCompanyNumber = (companyNumber) => {
    const regex = /^[0-9]+$/;

    if(companyNumber === '') {
      setErrCompanyNumber('');
    }
    else if(!regex.test(companyNumber)) {
      setErrCompanyNumber('사업자등록번호는 숫자만 사용 가능합니다.');
    }
    else {
      setErrCompanyNumber('');
    }
  }
  const validateCompanyEmail = (companyEmail) => {
    const regex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

    if(companyEmail === '') {
      setErrCompanyEmail("")
    }
    else if(!regex.test(companyEmail)) {
      setErrCompanyEmail("올바른 이메일을 입력해주세요.")
    }
    else {
      setErrCompanyEmail("");
    }
  }
  const validateCompanyPhone = (companyPhone) => {
    const regex = /^[0-9]{9,11}$/;

    if(companyPhone === '') {
      setErrCompanyPhone('');
    }
    else if(!regex.test(companyPhone)) {
      setErrCompanyPhone("연락처는 9~11자리 숫자만 사용 가능합니다.");
    }
    else {
      setErrCompanyPhone("");
    }
  }

  const InstituteBlock = ({id, name, description, icon}) => {
    const onClick = () => {
      setSelectedInstitute(id);
    }

    const getHiddenName = () => {
      const len = name.length;
      return name.substring(0, 1) + "*".repeat(len - 2) + name.substring(len - 1, len);
    }

    return (
      <Box display={onlyShowSelectedInstitute && selectedInstitute !== id ? "none" : "flex"} width={onlyShowSelectedInstitute  ? "550px" : "350px"} flexDirection="row" onClick={onClick}
           borderRadius="10px" border={"1px solid " + (selectedInstitute === id ? "rgba(2, 14, 130, 0.20)" : "#E0E0E0")}
           padding="10px" sx={{ backgroundColor: selectedInstitute === id ? "rgba(2, 14, 130, 0.20)" : "white", cursor: "pointer" }}>
        <Box><Box width="75px" height="75px" component="img" src={`/images/institute/${icon}.png`}/></Box>
        <Box display="flex" flexDirection="column" gap="6px"  margin="auto" marginLeft="16px">
          {/* 로그인 한 경우 이름 전체, 비로그인 일 경우 이름 앞, 뒤 글자만 나타냄 */}
          <Typography fontSize="16px" fontWeight="700">{flagLogin ? name : getHiddenName()}</Typography>
          <Typography fontSize="14px">{description}</Typography>
        </Box>
      </Box>
    )
  }

  return (
    <>
      <Dimmed show={show} setShow={setShow}/>
      <Box ref={ref} display={show?null:"none"} id="educ01p" position="absolute" minWidth="760px" top="30%" className="modal-wrap box" padding="30px 40px 40px 40px" sx={{ backgroundColor: "white" }}>
        <CloseIconButton setShow={setShow} />
        <Box display="flex" flexDirection="column" marginTop="20px">
          <Box display="flex" justifyContent="center"><Box component="img" height="46px" src="/images/icon.png"/></Box>
          <Typography fontSize="20px" textAlign="center" marginTop="10px">아이두와 함께하는 추천 교육원과 무료로 상담받으세요!</Typography>

          {/* 진단 페이지에서는 안 보임*/}
          <Box display={diagnosis === null ?"flex":"none"} justifyContent="center" marginTop="20px">
            <Typography fontSize="16px"><Box component="span" fontWeight="700" marginRight="10px">TIP</Box>진단 결과를 기반으로 무료 상담을 받으면 더욱 더 정확한 파악이 가능합니다.</Typography>
            {/* 진단 페이지에서는 안 보임*/}

            <Box display="flex" marginLeft="20px" sx={{ cursor: "pointer" }} onClick={() => {setShow(false); navigate('/diagnosis')}}>
              <KeyboardArrowRightIcon sx={{ margin: "auto", fontSize: "24px" }}/>
              <Typography fontSize="16px" fontWeight="700" color="#020E82">진단하러 가기</Typography>
            </Box>
          </Box>

          <Box display="flex" justifyContent="center" gap="16px" marginTop="10px" padding="20px 0">
            {
              instituteList?.map((item, idx) => (
                <InstituteBlock key={idx} id={item.instituteId} name={item.name} description={item.modalDescription} icon={item.icon}/>
              ))
            }
          </Box>

          <Box padding="10px 90px 20px 90px">
            <Box display="flex" flexDirection="column" gap="10px">
              <Typography fontSize="16px"><Box component="span" fontWeight="700" marginRight="8px">기업정보</Box>기업의 정보는 블라인드 처리 후 교육원에 전달합니다.</Typography>

              <Box display="flex" gap="17px" flexDirection="column" padding="20px 10px" marginBottom="20px">
                {/* 사업자등록번호 */}
                <Box display="flex" gap="10px" justifyContent="flex-start">
                  <Typography marginTop="15px" width="125px" fontSize="16px">사업자등록번호</Typography>
                  <TextField placeholder="사업자등록번호를 -없이 입력해주세요." disabled={flagLogin}
                             value={companyNumber} onChange={changeCompanyNumber}
                             error={errCompanyNumber !== ""} helperText={errCompanyNumber}
                             sx={{width: '330px', '.MuiInputBase-input': { fontSize: '16px' }}}/>
                  <Box><Button variant="outlined" onClick={clickValidateCompanyNumber}
                               sx={{display: flagLogin?"none":null, width: "120px", border: "1px solid #6A6A6A", color: "#6A6A6A", padding: "11px 15px"}}>사업자 확인</Button></Box>
                </Box>

                <Box display="flex" gap="10px" justifyContent="flex-start">
                  <Typography marginTop="15px" width="125px" fontSize="16px">회사 이메일</Typography>
                  <TextField placeholder="이메일 주소를 입력해주세요." value={companyEmail} onChange={changeCompanyEmail}
                             error={errCompanyEmail !== ""} helperText={errCompanyEmail}
                             sx={{width: '330px', '.MuiInputBase-input': { fontSize: '16px' }}}/>
                </Box>

                <Box display="flex" gap="10px" justifyContent="flex-start">
                  <Typography marginTop="15px" width="125px" fontSize="16px">회사 연락처</Typography>
                  <TextField placeholder="회사 연락처를 - 없이 입력해주세요." value={companyPhone} onChange={changeCompanyPhone}
                             error={errCompanyPhone !== ""} helperText={errCompanyPhone}
                             sx={{width: '330px', '.MuiInputBase-input': { fontSize: '16px' }}}/>
                </Box>
              </Box>
            </Box>

            <Box display="flex" flexDirection="column" gap="9px">
              <Typography fontSize="16px" fontWeight="700">교육원에 추가로 전달할 내용을 모두 선택해주세요. </Typography>

              {
                checkboxContents.map((item, idx) => (
                  <FormControlLabel key={idx} sx={{height: "30px"}} label={<span style={{fontSize: "16px"}}>{item}</span>}
                                    control={<Checkbox id={idx + ""} size="small" onChange={checkCheckbox} />}/>
                ))
              }
            </Box>
          </Box>

          <Box height="1px" margin="10px 0" sx={{backgroundColor: "#6A6A6A"}}/>

          <Box padding="10px 90px 20px 90px">
            <Typography fontSize="16px" fontWeight="700" marginBottom="9px">약관동의</Typography>
            <FormControlLabel sx={{height: "30px"}} label={<span style={{fontSize: "16px"}}>교육 상담을 위한 진단 내역 저장 및 교육원으로의 전달에 동의합니다.</span>}
                              control={<Checkbox size="small" onChange={(e) => setCheckAgree(e.target.checked)}/>}/>
          </Box>

          <Box display="flex" justifyContent="center" marginTop="20px">
            <Button variant="contained" sx={{padding: "15px 30px"}} onClick={clickEnter}>1:1 상담 요청하기</Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}
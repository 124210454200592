import { Dimmed } from "../components/Dimmed";
import { Box, Button, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { UIDataManager } from "../../common/UIDataManager";
import { useNavigate } from "react-router-dom";
import { setCookie } from "../../common/CookieUtils";
import { useState } from "react";

export default function BetaEventPopup({show, setShow}) {

  const [isCheck, setCheck] = useState(false);

  const navigate = useNavigate();

  const clickClose = () => {
     if(isCheck) {
       const today = new Date();
       setCookie('betaEventPopup', 'Y', {expires: new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)});
     }

    setShow(false);
  }

  return (
    <>
      <Box className="modal-wrap" position="absolute" top={{xs: "600px", md: "500px"}} left={{xs: "60%", md: "70%"}} flexDirection="column" justifyContent="center" paddingTop="40px"
           sx={{ gap: "2px", zIndex: 199, backgroundColor: "white", border: "1px solid #6A6A6A", zoom: {xs: 0.6, md: 1}}}>
        <Box display="flex" justifyContent="center" marginBottom="26px"><Box component="img" src="/images/logo.png" width="102px" height="32px"/></Box>

        <Typography fontSize="15px" fontWeight="700" textAlign="center">ai 기반 법정의무교육 진단 서비스 아이두</Typography>
        <Typography fontSize="24px" fontWeight="900" textAlign="center">무료 상담부터 스타벅스 커피까지!</Typography>
        <Typography color="#437FEC" fontSize="13px" fontWeight="500" textAlign="center">베타테스트에 참여해주셔서 진심으로 감사드립니다.</Typography>

        <Box component="img" src="/images/popup/img_main_beta_event.png" width="500px" height="331px" marginTop="26px" marginBottom="30px"></Box>

        <Box display="flex" justifyContent="center">
          <Button variant="contained" sx={{padding: "10px 40px", borderRadius: "0px", fontSize: "16px"}} onClick={() => {navigate('/diagnosis'); setShow(false)}}>지금 바로 법정의무교육 진단해보기</Button>
        </Box>

        <Box marginTop="40px" padding="16px 20px 16px 10px" sx={{ backgroundColor: "#437FEC" }}>
          <Box component="ul"
               sx={{
                 display: "grid",
                 gap: "4px",
                 marginLeft: "20px",
                 gridTemplateColumns: "repeat(1, 1fr)",
                 fontSize: "12px",
                 color: "white",
               }}
          >
            <Box component="li">본 이벤트는 베타테스트에 참여한 기업을 대상으로 진행하는 이벤트입니다.</Box>
            <Box component="li">테스트는 아이두 서비스를 이용한 법정의무교육 진단과 상담 과정을 포함합니다.</Box>
            <Box component="li">교육 상담은 업계 경력 8년 이상 베테랑 상담사가 진행합니다.</Box>
            <Box component="li">진단 서비스에는 별도로 비용이 부과되지 않으며 원하실 경우에만 교육기관과 매칭하여 드립니다. </Box>
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between" padding="2px 15px">
          <FormControlLabel label={<span style={{fontSize: "16px"}}>오늘 하루 보지 않기</span>} control={<Checkbox id="0" size="small"  />} onChange={event => setCheck(event.target.checked)}/>
          <Button sx={{color: "black", fontSize: "16px", fontWeight: "400", textDecoration: "underline"}} onClick={clickClose}>닫기</Button>
        </Box>
      </Box>
    </>
  )
}
export class UIDataManager {

  static loginModalInfo = {};
  static summaryModalInfo = {};

  static shareEmailPopupInfo = {};
  static oneButtonPopupInfo = {};
  static twoButtonPopupInfo = {};

  static setShareEmailPopupInfo(object) {
    this.shareEmailPopupInfo = {
      ...object
    };
  }

  static setLoginModalInfo(object) {
    this.loginModalInfo = {
      ...object
    };
  }

  static setSummaryModalInfo(object) {
    this.summaryModalInfo = {
      ...object
    };
  }

  static setOneButtonPopupInfo(object) {
    this.oneButtonPopupInfo = {
      ...object
    };
  }

  static setTwoButtonPopupInfo(object) {
    this.twoButtonPopupInfo = {
      ...object
    };
  }

}
import { AppBar, Box, Button, IconButton, Link, Toolbar, Typography } from "@mui/material";
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useRef, useState } from "react";
import useAuth from "../../common/useAuth";
import BetaWarningPopup from "../popup/BetaWarningPopup";
import { useSnackbar } from "notistack";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { UIDataContext } from "../../common/UIDataContext";
import { UIDataManager } from "../../common/UIDataManager";

export default function Header({ flagLogin, setFlagLogin, isNavigationGuard = false }) {
  const dropDownRef = useRef();

  const [isOpenDropDown, setOpenDropDown] = useState(false);
  const [loginUser, setLoginUser] = useState(null);

  const { setOpenLoginModal, setOpenRegisterModal, setOpenTwoButtonPopup, setOpenBetaWarningPopup } = useContext(UIDataContext);
  const { requestLoginUser, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const { pathname } = useLocation();
  const navigate = useNavigate();


  useEffect(() => {
    requestLoginUser((data) => {
      setLoginUser(data);
    }, () => {
      setLoginUser(null);
    });
  }, [flagLogin]);

  /**
   * 우측 drop down event
   */
  useEffect(() => {
    const handleClickOutside = (event) => {
      if ((dropDownRef.current && !dropDownRef.current.contains(event.target)) && isOpenDropDown) {
        setOpenDropDown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const clickLogo = () => {
    if (isNavigationGuard) {
      // 회원가입
      if (pathname.includes("register")) {
        UIDataManager.setTwoButtonPopupInfo({
          content:"이 페이지를 벗어나면 입력하셨던 내용이 모두 사라질 수 있습니다.\n페이지를 이동하시겠습니까?",
          btn1Text: "이동하기",
          btn2Text: "계속 가입하기",
          btn1Fun: () => navigate('/')
        });
      }
      else if(pathname.includes("diagnosis")) {
        UIDataManager.setTwoButtonPopupInfo({
          content: "이전 페이지로 돌아갈 시 진단 중이던 내용이 초기화됩니다.\n이전 페이지로 돌아가시겠습니까?",
          btn1Text: "이동하기",
          btn2Text: "계속 진단하기",
          btn1Fun: () => navigate('/')
        });
      }

      setOpenTwoButtonPopup(true);
    }
    else {
      navigate('/');
    }
  }

  const clickLogin = () => {
    if (isNavigationGuard) {
      // 회원가입
      if (pathname.includes("register")) {
        UIDataManager.setTwoButtonPopupInfo({
          content:"이 페이지를 벗어나면 입력하셨던 내용이 모두 사라질 수 있습니다.\n페이지를 이동하시겠습니까?",
          btn1Text: "이동하기",
          btn2Text: "계속 가입하기",
          btn1Fun: () => navigate('/login')
        });
      }
      else if(pathname.includes("diagnosis")) {
        UIDataManager.setTwoButtonPopupInfo({
          content: "이전 페이지로 돌아갈 시 진단 중이던 내용이 초기화됩니다.\n이전 페이지로 돌아가시겠습니까?",
          btn1Text: "이동하기",
          btn2Text: "계속 진단하기",
          btn1Fun: () => navigate('/login')
        });
      }

      setOpenTwoButtonPopup(true);
    }
    else {
      navigate('/login');
    }
  }

  const clickRegister = () => {
    if (isNavigationGuard) {
      // 회원가입
      if (pathname.includes("register")) {
        return;
      }
      else if(pathname.includes("diagnosis")) {
        UIDataManager.setTwoButtonPopupInfo({
          content: "이전 페이지로 돌아갈 시 진단 중이던 내용이 초기화됩니다.\n이전 페이지로 돌아가시겠습니까?",
          btn1Text: "이동하기",
          btn2Text: "계속 진단하기",
          btn1Fun: () => navigate('/register')
        });
      }
      setOpenTwoButtonPopup(true);
    }
    else {
      navigate('/register');
    }
  }

  const clickLogout = () => {
    UIDataManager.setTwoButtonPopupInfo({
      title: "로그아웃",
      content: "지금 로그아웃 하시겠습니까?",
      btn1Text: "로그아웃",
      btn2Text: "취소",
      btn1Fun: () => {
        logout();
        setOpenDropDown(false);
        setFlagLogin(false);
        enqueueSnackbar("아이두에서 로그아웃 되었습니다.", {variant: "default"});
      }
    });

    setOpenTwoButtonPopup(true);
  }

  const clickLoginMobile = () => {
    UIDataManager.setLoginModalInfo({
      succFun: () => {
        requestLoginUser((data) => {
          setFlagLogin(true);
          setLoginUser(data);
        }, () => {
          setLoginUser(null);
          setFlagLogin(false);
        });
      }
    });
    setOpenLoginModal(true);
  }

  const clickRegisterMobile = () => {
    setOpenRegisterModal(true);
  }

  const getCustomCompanyNumber = () => {
    const companyNumber = loginUser?.companyNumber;
    if(companyNumber) {
      return `${companyNumber.slice(0, 3)}-${companyNumber.slice(3, 5)}-${companyNumber.slice(5)}`;
    }
  }

  return (
    <Box component="header" display="flex" width="100%" >

      {/* PC */}
      <Box display={{xs: "none", md: "flex"}} width="100%" maxWidth="1400px" alignContent="center" justifyContent="space-between" margin="0 auto" height="80px">
        <Box display="flex" margin="auto 20px">
          <Button onClick={clickLogo}>
            <Box component="img" src="/images/logo.png" height="50px"/>
          </Button>
        </Box>
        {/* 비로그인 */}
        <Box display={flagLogin?"none":"flex"} margin="auto 20px" gap="16px">
          <Button onClick={clickLogin} sx={{width: {xs: "70px", md: "120px"}, fontSize: {xs: "10px", md: "18px"}}} variant="text">로그인</Button>
          <Button onClick={clickRegister} sx={{width: {xs: "70px", md: "120px"}, fontSize: {xs: "10px", md: "18px"}}} variant="contained">회원가입</Button>
        </Box>

        {/* 로그인 */}
        <Box display={flagLogin?"flex":"none"} margin="auto 20px" gap="16px">
          <Box display="flex" flexDirection="row" gap="4px" onClick={() => setOpenDropDown(prevState => !prevState)} sx={{ textDecoration: "underline", cursor: "pointer"}}>
            <KeyboardArrowLeftIcon  sx={{marginTop: "4px", transform: "rotate(270deg)", fontSize: "24px", fontWeight: "700", color: "#1B1B1B" }}/>
            <Typography fontWeight="600" fontSize="18px" color="#000000" >
              {getCustomCompanyNumber()}
            </Typography>
          </Box>

          <Box ref={dropDownRef} display={isOpenDropDown?null:"none"} position="absolute" top="70px" marginLeft="10px" width="137px">
            <Typography onClick={() => setOpenBetaWarningPopup(true)} fontSize="16px" padding="8px 0 8px 8px" border="1px solid #E0E0E0"
                        sx={{ backgroundColor: "white", cursor: "pointer"}}>마이페이지</Typography>

            <Typography onClick={clickLogout} fontSize="16px" padding="8px 0 8px 8px" border="1px solid #E0E0E0"
                        sx={{ backgroundColor: "white", cursor: "pointer"}}>로그아웃</Typography>
          </Box>
        </Box>
      </Box>

      {/* mobile */}
      <Box display={{xs: "block", md: "none"}} width="100%">
        <AppBar position="static">
          <Toolbar>
            {/*<IconButton*/}
            {/*  size="large"*/}
            {/*  edge="start"*/}
            {/*  color="inherit"*/}
            {/*  aria-label="menu"*/}
            {/*>*/}
            {/*  <MenuIcon />*/}
            {/*</IconButton>*/}
            <Box flexGrow={1}>
              <Box component="img" src="/images/light_logo.png" width="77px" height="23px" onClick={clickLogo}/>
            </Box>

            <Box display={flagLogin?"flex":"none"}>
              <Box display="flex" flexDirection="row" gap="4px" onClick={() => setOpenDropDown(prevState => !prevState)} sx={{ cursor: "pointer"}}>
                <KeyboardArrowLeftIcon  sx={{marginTop: "2px", transform: "rotate(270deg)", fontSize: "20px", fontWeight: "700", color: "#FFFFFF" }}/>
                <Typography fontSize="14px" color="#FFFFFF" >
                  {getCustomCompanyNumber()}
                </Typography>
              </Box>

              <Box ref={dropDownRef} display={isOpenDropDown?null:"none"} position="absolute" top="40px" marginLeft="10px" width="105px">
                <Typography onClick={() => setOpenBetaWarningPopup(true)} fontSize="14px" padding="8px 0 8px 8px" border="1px solid #E0E0E0" color="black"
                            sx={{ backgroundColor: "white", cursor: "pointer"}}>마이페이지</Typography>

                <Typography onClick={clickLogout} fontSize="14px" padding="8px 0 8px 8px" border="1px solid #E0E0E0" color="black"
                            sx={{ backgroundColor: "white", cursor: "pointer"}}>로그아웃</Typography>
              </Box>
            </Box>
            <Box display={flagLogin?"none":"flex"}>
              <Typography fontSize="14px" onClick={clickLoginMobile}>로그인</Typography>
              <Typography fontSize="10px" padding="0 8px">|</Typography>
              <Typography fontSize="14px" onClick={clickRegisterMobile}>회원가입</Typography>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    </Box>
  )
}